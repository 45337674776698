<template>
  <div class="ma-auto mt-4 maxWidth-1100">
    <p class="text-left mt-5">{{ $t( "signedin.OrganisationHas" ) }} {{allSignatureCount}} {{ $t( "signedin.SignaturesCreated" ) }}.
      {{ $t( "signedin.AllSignatureHas" ) }} {{allSignaturePeriodCount}} {{ $t( "signedin.AdvancedSignatureHas" ) }}
      {{advancedSignaturePeriodCount}}.</p>
    <v-row class="mt-2">
      <v-col cols="12" sm="12" md="9" lg="9" class="pb-1">
        <v-autocomplete
            v-model="selected_signature_type_items"
            :items="localizedSignatureTypes"
            dense
            chips
            small-chips
            return-object
            :label="$t( 'signedin.SelectSignatureTypes' )"
            @change="selectSignatureTypes"
            multiple
            v-on:change="all_signatures = null"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="12" md="3" lg="3" class="pb-1 radio-padding">
        <v-radio-group v-model="all_signatures">
          <v-radio class="whole-organisation" :label="$t( 'signedin.AllSignature' )" value="all"
                   @click="loadSignatureChart()" v-on:change="selected_signature_type_items = null; selected_signature_types = null"></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <div>
      <v-row>
        <v-col cols="6">
          <v-menu
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  :value="formatFromDate"
                  :label="$t( 'signedin.From' )"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
                class="from-date"
                v-model="from_date"
                :locale="fullLocale"
                :first-day-of-week="1"
                @input="loadSignatureChart();"
                :max="new Date().toISOString().substr(0, 10)"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="6">
          <v-menu
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  :value="formatToDate"
                  :label="$t( 'signedin.To' )"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
                class="to-date"
                v-model="to_date"
                :locale="fullLocale"
                :first-day-of-week="1"
                @input="loadSignatureChart();"
                :max="new Date().toISOString().substr(0, 10)"
                :min="from_date"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
    </div>
    <p class="text-left mt-5">{{ $t( "signedin.ChartDescriptionSignature" ) }}</p>
    <div class="mt-6">
      <line-chart ref="signature_chart" v-if="signature_chart.data.chartdata" :options="signature_chart.data.options"
                  :chartdata="signature_chart.data.chartdata"/>
    </div>
    <div>
      <v-row justify="center">
        <v-col cols="12">
          <p class="mt-10 font-weight-bold mb-3 text-left table-header">{{ $t( "signedin.UserActivity" ) }}</p>
          <v-data-table
              class="user-activity-table"
              dense
              disable-filtering
              disable-pagination
              fixed-header
              :headers="signatureStatsHeaders"
              hide-default-footer
              :items="userSignatureCounters"
              :item-class="itemRowFontColor">
          </v-data-table>
        </v-col>
      </v-row>
    </div>
    <div class="text-left mx-4 mt-6">
      <v-icon class="mr-1">mdi-download</v-icon>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <a @click="downloadSignatureStats()" class="pink-alone-links">
                  {{ $t( "signedin.DownloadSignatureStatsLink" ) }}
                </a>
              </span>
        </template>
        <span>{{ $t( "signedin.DownloadSignatureStatsMessage" ) }}</span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
    import SignatureStats from "./SignatureStats";
    import LineChart from "@cloudlace/santra-ui/src/components/common/LineChart";
    import chartUtil from "@cloudlace/santra-ui/src/components/common/chart-util";
    import statsMixin from "./stats-mixin";
    import Vue from "vue";
    import util from "../../../util/util";
    export default {
        name : "SignatureStats",
        components : {
            LineChart
        },
        props : {
            signatureCounterStatus : String
        },
        mixins : [ statsMixin ],
        data()
        {
            const from = Date.now() - (30 * 24 * 3600 * 1000);
            const to = Date.now();
            return {
                signature_stats_headers : [],
                users : [],
                userSignatureCounters : [],
                counterStatus : "",
                allSignatureCount : 0,
                allSignaturePeriodCount : 0,
                advancedSignaturePeriodCount : 0,
                activeUsersCount : 0,
                inactiveUsersCount : 0,
                curLocale : util.getLocale(),
                all_signatures : "all",
                from_date : new Date( from ).toISOString().substring( 0, 10 ),
                to_date : new Date( to ).toISOString().substring( 0, 10 ),
                date_format : "dd.MM.yyyy",
                time_format : "dd.MM, HH:mm",
                from_menu : false,
                to_menu : false,
                color : 0,
                colors : [ "#f44336",
                           "#2196f3",
                           "#ff9800",
                           "#009688",
                           "#e91e63",
                           "#03a9f4",
                           "#ff5722",
                           "#4caf50",
                           "#795548",
                           "#3f51b5",
                           "#8bc34a",
                           "#607d8b",
                           "#9c27b0",
                           "#00bcd4" ],
                signature_chart : {
                    name : "signhero-signature-stats",
                    title : "Filters",
                    props : {
                        resolution : 'day',
                        span : 30 * 24 * 3600 * 1000,
                        agg : "max"
                    },
                    data : {
                        options : {
                            responsive : true,
                            maintainAspectRatio : false,
                            scales : {
                                yAxes : [ {
                                    ticks : {
                                        suggestedMax: 1, // Hides -1 when all zeros
                                        precision : 0 // Only integers
                                    }
                                } ]
                            },
                            animation : {
                                duration : 0
                            }
                        }
                    },
                    sources : []
                },
                selected_signature_types : null,
                selected_signature_type_items : null,
                enabled_signature_types : [ "email-based-signature" ]
            }
        },
        computed : {
            signatureStatsHeaders()
            {
                let headers = [ {
                    text: this.$t( 'signedin.User' ),
                    value: 'name',
                }, {
                    text: this.$t( 'signedin.Email' ),
                    value: 'email'
                } ];
                for( let i = 0; i < this.enabled_signature_types.length; i++ )
                {
                    if( this.selected_signature_types === null ||
                        this.selected_signature_types.length === 0 ||
                        this.selected_signature_types.indexOf( this.enabled_signature_types[ i ] ) !== -1 )
                    {
                        headers.push( {
                            text: this.$t( 'signedin.SignatureTypeCountInPeriod!' + this.enabled_signature_types[ i ] ),
                            align: 'center',
                            value: this.enabled_signature_types[ i ] + 'PeriodCount'
                        } );
                    }
                }
                headers.push( {
                    text: this.$t( "signedin.AllSignatureInPeriod" ),
                    align: 'center',
                    value: 'signaturePeriodCount'
                },
                {
                    text: this.$t( "signedin.AllSignature" ),
                    align: 'center',
                    value: 'signatureCurrentCount'
                } );
                return headers;
            },
            localizedSignatureTypes()
            {
                return this.enabled_signature_types.map( signature_type => ({
                    value: signature_type,
                    text: this.$t("signedin.SignatureType!" + signature_type )
                }));
            }
        },
        methods : {
            loadSignatureChart()
            {
                return new Promise( ( resolve, reject ) =>
                {
                    const groupId = util.getOrganizationId();
                    const statsFrom = new Date( this.from ).toISOString();
                    const statsTo = new Date( this.to ).toISOString();
                    this._showSignatureActivitySummary( groupId, statsFrom, statsTo );
                    const sources = this._getSignatureSources();
                    this.signature_chart.sources = sources;
                    Promise.all( sources.map( source =>
                    {
                        return this.$store.signFlowClient.retrieveCounter( groupId, source.counter, {
                            resolution : this.resolution,
                            statsFrom : statsFrom,
                            statsTo : statsTo,
                            agg : this.signature_chart.props.agg,
                            userId : source.userId
                        } );
                    } ) ).then( r =>
                    {
                        if( this.signature_chart.sources !== sources )
                        {
                            return resolve();
                        }
                        let datasets = [];
                        let series;
                        for( let i = 0; i < sources.length; i++ )
                        {
                            const data = r[ i ];
                            const source = sources[ i ];
                            if( data && source.display )
                            {
                                series = data.counter_values;
                                datasets.push( {
                                    ...source,
                                    data : series.map( point => point.value )
                                } );
                                this._calculatePeriodCount( source, series );
                            }
                        }
                        datasets.sort( ( a, b ) =>
                        {
                            return Math.max.apply( Math, b.data ) - Math.max.apply( Math, a.data )
                        } );
                        this.assignColors( datasets, this.signature_chart );
                        Vue.set( this.signature_chart.data, "chartdata",
                        {
                            labels : chartUtil.getLabels( this, series ),
                            datasets : datasets
                        } );
                        if( this.$refs.signature_chart )
                        {
                            // since reactivity is 'not robust', update the chart manually
                            this.$refs.signature_chart.renderChart( this.signature_chart.data.chartdata, this.signature_chart.data.options );
                        }
                        resolve();
                    } ).catch( e =>
                    {
                        this.$error( this.$t( 'message.Error!Unexpected' ) );
                        reject( e );
                    } );
                } );
            },
            _getSignatureSources()
            {
                const sources = [
                    {
                        display : true,
                        counter : "all-signature",
                        label : this.$t( "signedin.AllSignature" ),
                        fill : false,
                        lineTension : 0
                    }];
                for( let i = 0; i < this.enabled_signature_types.length; i++ )
                {
                    if( this.selected_signature_types === null ||
                        this.selected_signature_types.length === 0 ||
                        this.selected_signature_types.indexOf( this.enabled_signature_types[ i ] ) !== -1 )
                    {
                        sources.push( {
                            display : true,
                            counter : this.enabled_signature_types[ i ],
                            label : this.$t( 'signedin.SignatureType!' + this.enabled_signature_types[ i ] ),
                            fill : false,
                            lineTension : 0
                        } );
                    }

                }
                return sources;
            },
            selectSignatureTypes()
            {
                this.selected_signature_types = this.selected_signature_type_items.map( item => item.value );
                this.loadSignatureChart( true );
            },
            _calculatePeriodCount( source, series )
            {
                if( !source.userId )
                {
                    const count = series[ series.length - 1 ].value - series[ 0 ].value;
                    switch( source.counter )
                    {
                        case "all-signature":
                            this.allSignatureCount = series[ series.length - 1 ].value;
                            this.allSignaturePeriodCount = count;
                            break;
                        case "email-based-signature":
                            break;
                        default:
                            this.advancedSignaturePeriodCount += count;
                    }
                }
            },
            _showSignatureActivitySummary( groupId, statsFrom, statsTo )
            {
                this.$store.signFlowClient.retrieveUserSignatureCounterSummary(
                groupId, statsFrom, statsTo, ( this.selected_signature_types === null )
                ? this.enabled_signature_types : this.selected_signature_types ).then( r =>
                {
                    this.userSignatureCounters = r.userSignatureCounters;
                } ).catch( e =>
                {
                    this.$error( this.$t( "message.Error!Unexpected" ) );
                });
            },
            retrieveUsers()
            {
                this.$store.shioCoreClient.retrieveUsers( util.getOrganizationId() ).then( r =>
                {
                    this.users = r.entities;
                    this.activeUsersCount = this.users.filter( ( obj ) => obj.active === true && obj.disabled !== true ).length;
                    this.inactiveUsersCount = this.users.length - this.activeUsersCount;
                    this.users.sort(( a , b ) => a.name.localeCompare( b.name, this.curLocale  ) );
                    this.loadSignatureChart();
                } ).catch( e => this.$error( this.$t( "signedin.Error!Unexpected" ) ) );
            },
            downloadSignatureStats()
            {
                const rows = [];
                const headers = this.signatureStatsHeaders;
                rows.push( headers.map( header => { return header.text } ).join( ',' ) );
                for( let i = 0; i < this.userSignatureCounters.length; i++ )
                {
                    const values = headers.map( header => {
                        const escaped = ( '' + this.userSignatureCounters[ i ][ header.value ]).replace(/"/g, '\\"' );
                        return `"${escaped}"`;
                    });
                    rows.push( values.join( ',' ) );
                }
                const csvString = rows.join( '\n' );
                const blob = new Blob([ csvString ], { type: 'text/csv' });
                const url = URL.createObjectURL( blob );
                const link = document.createElement( 'a' );
                link.href = url;
                link.download = 'data.csv';
                link.click();
                URL.revokeObjectURL( url );
            }
        },
        mounted()
        {
            if( $cc_user_info.organization.enabled_signature_types )
            {
                this.enabled_signature_types = [ ...[ "email-based-signature" ], ...$cc_user_info.organization.enabled_signature_types ];
            }
            this.retrieveUsers();
        }
    }
</script>

<style lang="sass" scoped>
  @import "../../../styles/style"

  .from-date.v-card, .to-date.v-card
    margin: 0 !important

  .v-data-table.user-activity-table
    border: 0

  .table-header
    font-size: 20px

  @media(min-width: 1265px)
    .radio-padding
      padding-left: 75px

  @media(max-width: 960px)
    .radio-padding
      padding-top: 0
      padding-bottom: 15px !important
</style>


