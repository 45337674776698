<template>
  <v-container class="pt-4 maxWidth-1100">
    <v-overlay v-if="is_working">
      <v-progress-circular size="48" indeterminate/>
    </v-overlay>
    <h1 class="shio-section-title">{{ $t( "signedin.MySubscription" ) }}</h1>
    <v-responsive class="pt-5 px-4">
      <h3 class="text-left">{{ $t( 'signedin.CurrentPlan' ) }}</h3>
      <div>
        <v-row>
          <v-col cols="12" sm="8" md="8" class="py-0">
            <div class="text-left subscription-title-div">{{ $t( 'signedin.SignHeroSubscription' ) }}<span v-if="subscription.quantity > 1"> × {{ subscription.quantity }}</span>&nbsp;
                <div v-if="!subscription.has_trial_period" :class="subscription.status" class="subscription-status-div">{{  $t( 'signedin.subStatus!' + subscription.status ) }}</div>
                <div v-if="subscription.has_trial_period" :class="subscription.status" class="subscription-status-div">{{  $t( 'signedin.subStatus!' + subscription.status ) }} {{ subscription.trial_end }}</div>
            </div>
            <p class="text-left subscription-info">{{ $t( 'signedin.PriceInfo' ) }}
              <v-tooltip bottom>
                <template v-slot:activator="{on, attrs}">
                  <v-icon style="font-size: 20px;" v-bind="attrs" v-on="on">mdi-alert-circle</v-icon>
                </template>
                <span>{{ $t( "signedin.TaxTooltip" ) }}</span>
              </v-tooltip>
            </p>
            <p class="text-left subscription-info" v-if="subscription.has_payment_past_due">
              {{ $t( "signedin.PaymentOverdueAlert" ) }}
              <v-tooltip bottom>
                <template v-slot:activator="{on, attrs}">
                  <v-icon dark v-bind="attrs" v-on="on" class="overdue-alert-icon">
                    mdi-alert-circle
                  </v-icon>
                </template>
                <span>{{ $t( "signedin.PaymentOverdueAlertIgnore" ) }}</span>
              </v-tooltip>
            </p>
            <p class="text-left subscription-info" style="margin-bottom: 0px" v-if="!subscription.has_trial_period && !subscription.has_valid_subscription && !subscription.has_payment_past_due && !subscription.incomplete_subscription">
              {{ $t( "signedin.PleaseActivateSubscription" ) }}</p>
            <p class="text-left subscription-info" v-if="subscription.incomplete_subscription && has_card">
              {{ $t( "signedin.PleaseConfirmPayment" ) }}</p>
            <p class="text-left subscription-info" v-if="subscription.has_trial_period || subscription.has_valid_subscription || subscription.has_payment_past_due">
              {{ estimated_billing }}</p>
          </v-col>
          <v-col cols="12" sm="4" md="4" class="text-right py-0 cancel-plan-btn">
            <button v-if="subscription.has_trial_period
            || subscription.has_valid_subscription
            || subscription.has_payment_past_due
            || subscription.incomplete_subscription" id="cancelSubscriptionBtn" @click.prevent="" style="margin-top: 20px">
              <v-btn @click="cancelCurrentPlan" class="secondary-btn py-3 ma-auto" block>
                {{ $t( 'signedin.CancelPlan' ) }}
              </v-btn>
            </button>
            <button v-if="!subscription.has_trial_period
            && !subscription.has_valid_subscription
            && !subscription.has_payment_past_due
            && !subscription.incomplete_subscription" id="startSubscriptionBtn" @click.prevent="" style="margin-top: 20px">
              <v-tooltip bottom :disabled="has_card">
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                   <v-btn @click="createNewPlan" class="secondary-btn py-3 ma-auto mobile-center" block
                          :disabled="!has_card">
                     {{ $t( 'signedin.CreatePlan' ) }}
                   </v-btn>
                  </span>
                </template>
                <span>{{ $t( 'signedin.PleaseAddValidCard' ) }}</span>
              </v-tooltip>
            </button>
          </v-col>
        </v-row>
      </div>
      <v-divider class="mt-9"></v-divider>
      <div class="d-flex d-sm-none">
        <v-row>
          <v-col>
            <v-radio-group class="mt-4 subscription-tabs" v-model="tabStatus">
              <v-radio :label="$t( 'signedin.Tab1' )" value="payment_info" @click="goToPaymentInfo"></v-radio>
              <v-radio :label="$t( 'signedin.Tab2' )" value="billing_history" @click="goToBillingHistory">
                <v-icon
                    v-if="( subscription.incomplete_subscription && has_card ) || ( has_open_invoices && has_card && subscription.pending_subscription )"
                    v-bind="attrs"
                    v-on="on" class="incomplete-alert-icon">mdi-alert-circle
                </v-icon>
              </v-radio>
              <v-radio :label="$t( 'signedin.Tab3' )" value="about" @click="goToAbout"></v-radio>
            </v-radio-group>
            <div class="mt-8 text-left" :eager="true" v-if="paymentInfoSelect">
              <v-alert v-if="!has_card" border="bottom" colored-border type="error" elevation="3" class="mb-8">
                {{ $t("signedin.NoPaymentNotification") }}
              </v-alert>
              <form class="payment-form" id="payment-form" ref="paymentForm">
                <div>
                  <div class="billing-info-title">
                    <v-row>
                      <v-col cols="12" sm="6" md="6" class="py-0">
                        <p class="font-weight-bold">{{ $t("signedin.BillingInformation") }}</p>
                      </v-col>
                      <v-col cols="12" sm="6" md="6" class="text-right py-0">
                        <v-tooltip v-if="has_billing_info && !editing_address" top>
                          <template v-slot:activator="{on, attrs}">
                            <span v-bind="attrs" v-on="on">
                              <a style="display: inline" v-if="has_billing_info && !editing_address"
                                 @click="editAddress()">
                                {{ $t("signedin.Edit") }}
                              </a>
                            </span>
                          </template>
                          <span v-if="has_billing_info && !editing_address">
                            {{ $t("signedin.EditBillingInformation") }}
                          </span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </div>
                  <v-row>
                    <v-col cols="12" sm="12" md="12" class="py-0">
                      <v-text-field outlined :rules="[ validateEmail ]" v-model="email"
                                    :disabled="has_billing_info && !editing_address" clearable>
                        <template v-slot:label>
                          {{ $t('signedin.CreditCardEmail') }}<span class="red--text"> *</span>
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6" md="6" class="py-0">
                      <v-text-field class="pt-0 tax-id"
                                    v-model="address_line1"
                                    @change="onTaxIdChange()"
                                    :disabled="has_billing_info && !editing_address"
                                    outlined required>
                        <template v-slot:label>
                          {{ $t('signedin.Street') }}<span class="red--text"> *</span>
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" class="py-0">
                      <v-text-field class="pt-0 tax-id"
                                    v-model="address_line2"
                                    :label="$t( 'signedin.Street2' )"
                                    @change="onTaxIdChange()"
                                    :disabled="has_billing_info && !editing_address"
                                    outlined required>
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6" md="6" class="py-0">
                      <v-text-field class="pt-0 tax-id"
                                    v-model="address_city"
                                    @change="onTaxIdChange()"
                                    :disabled="has_billing_info && !editing_address"
                                    outlined required>
                        <template v-slot:label>
                          {{ $t('signedin.City') }}<span class="red--text"> *</span>
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" class="py-0">
                      <v-text-field class="pt-0 tax-id"
                                    v-model="address_postal_code"
                                    @change="onTaxIdChange()"
                                    :disabled="has_billing_info && !editing_address"
                                    outlined required>
                        <template v-slot:label>
                          {{ $t('signedin.Zip') }}<span class="red--text"> *</span>
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <!--<v-row>
                    <v-col cols="12" sm="6" md="6" class="py-0 mt-4">
                      <v-text-field outlined
                                    :label="$t( 'signedin.State' )"
                                    v-model="address_state"
                                    :disabled="has_billing_info && !editing_address">
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" class="py-0 mt-4">
                      <v-select class="select-country" v-model="address_country"
                                @change="prefillTaxTypes()"
                                :items="countriesJson"
                                item-text="country"
                                item-value="code"
                                :disabled="has_billing_info && !editing_address"
                                outlined
                                required>
                        <template v-slot:label>
                          {{$t( 'signedin.SelectCountry' )}}<span class="red--text"> *</span>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>-->
                  <v-row>
                    <v-col cols="12" sm="6" md="6" class="py-0">
                      <v-select class="select-country" v-model="address_country"
                                @change="prefillTaxTypes()"
                                :items="countriesJson"
                                item-value="code"
                                :disabled="has_billing_info && !editing_address"
                                outlined
                                required>
                        <template v-slot:label>
                          {{ $t('signedin.SelectCountry') }}<span class="red--text"> *</span>
                        </template>
                        <template v-slot:item="{ item }">
                          {{ $t("signedin.Country!" + item.country) }}
                        </template>
                        <template v-slot:selection="{ item, index }">
                          {{ $t("signedin.Country!" + item.country) }}
                        </template>
                      </v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" class="py-0">
                      <v-select class="select-country" v-model="preferred_locales"
                                :items="localesList"
                                item-text="name"
                                item-value="code"
                                :disabled="has_billing_info && !editing_address"
                                outlined
                                required>
                        <template v-slot:label>
                          {{ $t('signedin.SelectLocale') }}<span class="red--text"> *</span>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row class="mt-0 mb-4">
                    <v-col cols="12" sm="6" md="6" class="py-0">
                      <v-tooltip bottom :disabled="consumers_supported">
                        <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-checkbox class="mt-0"
                                    v-model="is_business_account"
                                    @change="onBusinessAccountChange()"
                                    :disabled="!consumers_supported || has_billing_info && !editing_address"
                                    :label="$t( 'signedin.BusinessAccount' )"/>
                      </span>
                        </template>
                        <span>{{ $t('signedin.OnlyBusinessAccountsSupported') }}</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                  <div class="tax-info-explanation mb-6"
                       v-if="is_business_account && ( !has_billing_info || editing_address )">
                    <p class="text-left">{{ $t("signedin.TaxIdExplanation") }}</p>
                  </div>
                  <v-row v-if="is_business_account">
                    <v-col cols="12" sm="6" md="6" class="py-0">
                      <v-select class="select-tax-id-type" v-model="tax_type"
                                :items="sortedTaxTypes"
                                item-value="enum"
                                :disabled="has_billing_info && !editing_address"
                                outlined required>
                        <template v-slot:label>
                          {{ $t('signedin.SelectIdType') }}<span class="red--text"> *</span>
                        </template>
                        <template v-slot:item="{ item }">
                          {{ $t("signedin.TaxType!" + item.enum) }}
                        </template>
                        <template v-slot:selection="{ item, index }">
                          {{ $t("signedin.TaxType!" + item.enum) }}
                        </template>
                      </v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" class="py-0">
                      <v-text-field class="pt-0 tax-id"
                                    v-model="tax_id"
                                    @change="onTaxIdChange()"
                                    :disabled="has_billing_info && !editing_address"
                                    outlined required>
                        <template v-slot:label>
                          {{ $t('signedin.TaxId') }}<span class="red--text"> *</span>
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-row>
                </div>
                <div class="mb-4">
                  <v-row no-gutters>
                    <v-col cols="12" sm="6" md="6" lg="6" xl="4" class="py-3 text-left mobile-center">
                      <button v-if="has_billing_info && editing_address" id="cancelEditBtn" @click.prevent="">
                        <v-btn @click="cancelEditBillingInfo" class="secondary-btn ma-auto" block>
                          {{ $t('signedin.Cancel') }}
                        </v-btn>
                      </button>
                    </v-col>
                    <v-col class="hidden-lg-and-down" cols="4">
                      <v-spacer/>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" lg="6" xl="4" class="py-3 text-right mobile-center">
                      <v-tooltip bottom :disabled="validateBillingInfoInputs">
                        <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                      <button class="save-billing-btn" v-if="!has_billing_info || editing_address"
                              id="addBillingInfoBtn"
                              @click.prevent="">
                        <v-btn block @click="saveBillingInformation()"
                               :disabled="!validateBillingInfoInputs || isBillingInfoOrig"
                               class="primary-btn ma-auto">{{ $t("signedin.SaveBillingInfo") }}</v-btn>
                      </button>
                      </span>
                        </template>
                        <span>{{ $t("signedin.AddBillingTooltip") }}</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </div>
                <div>
                  <p class="font-weight-bold">{{
                      has_card ? $t("signedin.CardInfo") : $t("signedin.AddCreditCard")
                    }}</p>
                  <div v-if="!has_card">
                    <v-row no-gutters>
                      <v-col cols="12" sm="6" md="6" class="pr-0 pr-sm-3 pr-md-3 pr-lg-3">
                        <div class="card-element" id="card-element" ref="cardNumber"></div>
                      </v-col>
                      <v-col cols="12" sm="6" md="6" class="pl-0 pl-sm-3 pl-sm-3 pl-lg-3">
                        <v-text-field outlined v-model="card_name" clearable>
                          <template v-slot:label>
                            {{ $t('signedin.NameOnCard') }}<span class="red--text"> *</span>
                          </template>
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col cols="12" sm="6" md="6" class="pr-0 pr-sm-3 pr-md-3 pr-lg-3">
                        <div class="card-element-second-line-left" ref="cardExpiry"></div>
                      </v-col>
                      <v-col cols="12" sm="6" md="6" class="pl-0 pl-sm-3 pl-sm-3 pl-lg-3">
                        <div class="card-element-second-line-right" ref="cardCvc"></div>
                      </v-col>
                    </v-row>
                  </div>
                  <div class="card-div mb-5" v-if="has_card">
                    <v-row no-gutters>
                      <v-col cols="12" sm="4" md="4" class="pr-0 pr-sm-3 pr-md-3 pr-lg-3">
                        {{ payment_method.brand }} **** {{ payment_method.last4 }}
                      </v-col>
                      <v-col cols="12" sm="4" md="4" class="pl-0 pl-sm-3 pl-sm-3 pl-lg-3">
                        {{ $t("signedin.Expires") }} {{ payment_method.exp_month }} / {{ payment_method.exp_year }}
                      </v-col>
                      <v-col cols="12" sm="4" md="4" class="text-right pl-0 pl-sm-3 pl-sm-3 pl-lg-3">
                        <v-tooltip bottom>
                          <template v-slot:activator="{on, attrs}">
                            <v-icon dark v-bind="attrs" v-on="on" class="remove-icon" @click="deleteCard()">
                              mdi-trash-can
                            </v-icon>
                          </template>
                          <span>{{ $t("signedin.DeleteCardOnly") }}</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </div>
                  <div class="text-right mobile-center">
                    <v-tooltip bottom>
                      <template v-slot:activator="{on, attrs}">
                        <button v-if="!has_card" v-bind="attrs" v-on="on" id="addCardBtn" @click.prevent="">
                          <v-btn block @click="obtainConsent()" :disabled="!validateCardInputs || !has_billing_info"
                                 class="primary-btn py-5 ma-auto">{{ $t("signedin.SaveCard") }}
                          </v-btn>
                        </button>
                      </template>
                      <span v-if="!has_billing_info">{{ $t("signedin.AddCardTooltip1") }}</span>
                      <span v-if="!validateCardInputs && has_billing_info">{{ $t("signedin.AddCardTooltip2") }}</span>
                      <span v-if="validateCardInputs && has_billing_info">{{ $t("signedin.AddCardTooltip3") }}</span>
                    </v-tooltip>
                  </div>
                </div>
                <div class="text-left" v-if="has_billing_info && !editing_address">
                  <v-divider class="mt-5 mb-3"/>
                  <div class="mt-5">
                    <p class="font-weight-light">{{ $t("signedin.DeletePaymentInfo") }}</p>
                    <div class="mobile-center">
                      <v-btn @click="removePaymentMethod()" class="secondary-btn">
                        {{ $t( "signedin.RemoveWholePaymentMethod" ) }}
                      </v-btn>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="mt-8" v-if="billingHistorySelect">
              <v-simple-table class="billing-history-table">
                <template v-slot:default>
                  <thead>
                  <tr class="text-left">
                    <th>
                      {{ $t("signedin.BillingDate") }}
                    </th>
                    <th>
                      {{ $t("signedin.Amount") }}
                    </th>
                    <th>
                      {{ $t("signedin.UsersAmount") }}
                    </th>
                    <th>
                      {{ $t("signedin.Duration") }}
                    </th>
                    <th>
                      {{ $t("signedin.ReferNumber") }}
                    </th>
                    <th>
                      {{ $t("signedin.Invoice") }}
                    </th>
                    <th>
                      {{ $t("signedin.Action") }}
                    </th>
                  </tr>
                  </thead>
                  <tbody class="text-left">
                  <tr class="billing-history-table-tr" :class="billingHistory.status" v-for="billingHistory in invoices"
                      v-if="billingHistory.lines.data[0]
                && (billingHistory.status === 'paid' || ( billingHistory.status === 'open' && has_card && subscription.pending_subscription ) ) && billingHistory.lines.data[0].amount !== 0">
                    <td>{{ util.getDateFromTimestamp(billingHistory.created) }}</td>
                    <td>{{ (billingHistory.amount_remaining + billingHistory.amount_paid) / 100 }}€</td>
                    <td>{{ billingHistory.lines.data[0].quantity }}</td>
                    <td>{{
                        util.getDatePeriod(billingHistory.lines.data[0].period.start, billingHistory.lines.data[0].period.end)
                      }}
                    </td>
                    <td>{{ billingHistory.number }}</td>
                    <td>
                      <a v-if="billingHistory.status === 'paid'" :href="billingHistory.invoice_pdf" target="_blank"
                         rel="noopener">{{ $t("signedin.PaymentStatusPaid") }}</a>
                      <a v-if="billingHistory.status === 'open'" :href="billingHistory.invoice_pdf" target="_blank"
                         rel="noopener">{{ $t("signedin.PaymentStatusPending") }}</a>
                    </td>
                    <td>
                      <a v-if="billingHistory.status === 'open'" :href="billingHistory.hosted_invoice_url"
                         target="_blank" rel="noopener">{{ $t("signedin.PayOnline") }}</a>
                    </td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <v-btn class="primary-btn mt-8" @click="loadMoreBilling()" v-if="has_more_billing">
                {{ $t("signedin.LoadMore") }}
              </v-btn>
            </div>
            <div class="mt-8 text-left" v-if="aboutSelect">
              <div>
                <p class="font-weight-bold">{{ $t("signedin.PaymentInfo") }}</p>
                <p>{{ $t("signedin.PaymentExplanation1") }}</p>
                <p v-html="$t( 'signedin.PaymentExplanation2' )"></p>
                <p v-html="$t( 'signedin.PaymentExplanation3' )"></p>
                <p v-html="$t( 'signedin.PaymentExplanation4' )"></p>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
      <div class="d-none d-sm-flex">
        <v-tabs class="mt-4 subscription-tabs">
          <v-tab class="tab-title">{{ $t("signedin.Tab1") }}</v-tab>
          <v-tab class="tab-title">{{ $t("signedin.Tab2") }}
            <v-icon
                v-if="( subscription.incomplete_subscription && has_card ) || ( has_open_invoices && has_card && subscription.pending_subscription )"
                v-bind="attrs"
                v-on="on" class="incomplete-alert-icon">mdi-alert-circle
            </v-icon>
          </v-tab>
          <v-tab class="tab-title">{{ $t("signedin.Tab3") }}</v-tab>
          <v-tab-item class="mt-8 text-left" :eager="true">
            <v-alert v-if="!has_card" border="bottom" colored-border type="error" elevation="3" class="mb-8">
              {{ $t("signedin.NoPaymentNotification") }}
            </v-alert>
            <form class="payment-form" id="payment-form" ref="paymentForm">
              <div>
                <div class="billing-info-title">
                  <v-row>
                    <v-col cols="12" sm="6" md="6" class="py-0">
                      <p class="font-weight-bold">{{ $t("signedin.BillingInformation") }}</p>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" class="text-right py-0">
                      <v-tooltip v-if="has_billing_info && !editing_address" top>
                        <template v-slot:activator="{on, attrs}">
                        <span v-bind="attrs" v-on="on">
                          <a style="display: inline" v-if="has_billing_info && !editing_address" @click="editAddress()">{{
                              $t("signedin.Edit")
                            }}</a>
                        </span>
                        </template>
                        <span v-if="has_billing_info && !editing_address">{{
                            $t("signedin.EditBillingInformation")
                          }}</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </div>
                <v-row>
                  <v-col cols="12" sm="12" md="12" class="py-0">
                    <v-text-field outlined :rules="[ validateEmail ]" v-model="email"
                                  :disabled="has_billing_info && !editing_address" clearable>
                      <template v-slot:label>
                        {{ $t('signedin.CreditCardEmail') }}<span class="red--text"> *</span>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="6" class="py-0">
                    <v-text-field class="pt-0 tax-id"
                                  v-model="address_line1"
                                  @change="onTaxIdChange()"
                                  :disabled="has_billing_info && !editing_address"
                                  outlined required>
                      <template v-slot:label>
                        {{ $t('signedin.Street') }}<span class="red--text"> *</span>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="py-0">
                    <v-text-field class="pt-0 tax-id"
                                  v-model="address_line2"
                                  :label="$t( 'signedin.Street2' )"
                                  @change="onTaxIdChange()"
                                  :disabled="has_billing_info && !editing_address"
                                  outlined required>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="6" class="py-0">
                    <v-text-field class="pt-0 tax-id"
                                  v-model="address_city"
                                  @change="onTaxIdChange()"
                                  :disabled="has_billing_info && !editing_address"
                                  outlined required>
                      <template v-slot:label>
                        {{ $t('signedin.City') }}<span class="red--text"> *</span>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="py-0">
                    <v-text-field class="pt-0 tax-id"
                                  v-model="address_postal_code"
                                  @change="onTaxIdChange()"
                                  :disabled="has_billing_info && !editing_address"
                                  outlined required>
                      <template v-slot:label>
                        {{ $t('signedin.Zip') }}<span class="red--text"> *</span>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
                <!--<v-row>
                  <v-col cols="12" sm="6" md="6" class="py-0 mt-4">
                    <v-text-field outlined
                                  :label="$t( 'signedin.State' )"
                                  v-model="address_state"
                                  :disabled="has_billing_info && !editing_address">
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="py-0 mt-4">
                    <v-select class="select-country" v-model="address_country"
                              @change="prefillTaxTypes()"
                              :items="countriesJson"
                              item-text="country"
                              item-value="code"
                              :disabled="has_billing_info && !editing_address"
                              outlined
                              required>
                      <template v-slot:label>
                        {{$t( 'signedin.SelectCountry' )}}<span class="red--text"> *</span>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>-->
                <v-row>
                  <v-col cols="12" sm="6" md="6" class="py-0">
                    <v-select class="select-country" v-model="address_country"
                              @change="prefillTaxTypes()"
                              :items="countriesJson"
                              item-value="code"
                              :disabled="has_billing_info && !editing_address"
                              outlined
                              required>
                      <template v-slot:label>
                        {{ $t('signedin.SelectCountry') }}<span class="red--text"> *</span>
                      </template>
                      <template v-slot:item="{ item }">
                        {{ $t("signedin.Country!" + item.country) }}
                      </template>
                      <template v-slot:selection="{ item, index }">
                        {{ $t("signedin.Country!" + item.country) }}
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="py-0">
                    <v-select class="select-country" v-model="preferred_locales"
                              :items="localesList"
                              item-text="name"
                              item-value="code"
                              :disabled="has_billing_info && !editing_address"
                              outlined
                              required>
                      <template v-slot:label>
                        {{ $t('signedin.SelectLocale') }}<span class="red--text"> *</span>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
                <v-row class="mt-0 mb-4">
                  <v-col cols="12" sm="6" md="6" class="py-0">
                    <v-tooltip bottom :disabled="consumers_supported">
                      <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-checkbox class="mt-0"
                                    v-model="is_business_account"
                                    @change="onBusinessAccountChange()"
                                    :disabled="!consumers_supported || has_billing_info && !editing_address"
                                    :label="$t( 'signedin.BusinessAccount' )"/>
                      </span>
                      </template>
                      <span>{{ $t('signedin.OnlyBusinessAccountsSupported') }}</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
                <div class="tax-info-explanation mb-6"
                     v-if="is_business_account && ( !has_billing_info || editing_address )">
                  <p class="text-left">{{ $t("signedin.TaxIdExplanation") }}</p>
                </div>
                <v-row v-if="is_business_account">
                  <v-col cols="12" sm="6" md="6" class="py-0">
                    <v-select class="select-tax-id-type" v-model="tax_type"
                              :items="sortedTaxTypes"
                              item-value="enum"
                              :disabled="has_billing_info && !editing_address"
                              outlined required>
                      <template v-slot:label>
                        {{ $t('signedin.SelectIdType') }}<span class="red--text"> *</span>
                      </template>
                      <template v-slot:item="{ item }">
                        {{ $t("signedin.TaxType!" + item.enum) }}
                      </template>
                      <template v-slot:selection="{ item, index }">
                        {{ $t("signedin.TaxType!" + item.enum) }}
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" class="py-0">
                    <v-text-field class="pt-0 tax-id"
                                  v-model="tax_id"
                                  @change="onTaxIdChange()"
                                  :disabled="has_billing_info && !editing_address"
                                  outlined required>
                      <template v-slot:label>
                        {{ $t('signedin.TaxId') }}<span class="red--text"> *</span>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
              </div>
              <div class="mb-4">
                <v-row no-gutters>
                  <v-col cols="12" sm="6" md="6" lg="6" xl="4" class="py-3 text-left mobile-center">
                    <button v-if="has_billing_info && editing_address" id="cancelEditBtn" @click.prevent="">
                      <v-btn @click="cancelEditBillingInfo" class="secondary-btn ma-auto" block>
                        {{ $t('signedin.Cancel') }}
                      </v-btn>
                    </button>
                  </v-col>
                  <v-col class="hidden-lg-and-down" cols="4">
                    <v-spacer/>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" lg="6" xl="4" class="py-3 text-right mobile-center">
                    <v-tooltip bottom :disabled="validateBillingInfoInputs">
                      <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                      <button class="save-billing-btn" v-if="!has_billing_info || editing_address"
                              id="addBillingInfoBtn"
                              @click.prevent="">
                        <v-btn block @click="saveBillingInformation()"
                               :disabled="!validateBillingInfoInputs || isBillingInfoOrig"
                               class="primary-btn ma-auto">{{ $t("signedin.SaveBillingInfo") }}</v-btn>
                      </button>
                      </span>
                      </template>
                      <span>{{ $t("signedin.AddBillingTooltip") }}</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </div>
              <div>
                <p class="font-weight-bold">{{ has_card ? $t("signedin.CardInfo") : $t("signedin.AddCreditCard") }}</p>
                <div v-if="!has_card">
                  <v-row no-gutters>
                    <v-col cols="12" sm="6" md="6" class="pr-0 pr-sm-3 pr-md-3 pr-lg-3">
                      <div class="card-element" id="card-element" ref="cardNumber"></div>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" class="pl-0 pl-sm-3 pl-sm-3 pl-lg-3">
                      <v-text-field outlined v-model="card_name" clearable>
                        <template v-slot:label>
                          {{ $t('signedin.NameOnCard') }}<span class="red--text"> *</span>
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12" sm="6" md="6" class="pr-0 pr-sm-3 pr-md-3 pr-lg-3">
                      <div class="card-element-second-line-left" ref="cardExpiry"></div>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" class="pl-0 pl-sm-3 pl-sm-3 pl-lg-3">
                      <div class="card-element-second-line-right" ref="cardCvc"></div>
                    </v-col>
                  </v-row>
                </div>
                <div class="card-div mb-5" v-if="has_card">
                  <v-row no-gutters>
                    <v-col cols="12" sm="4" md="4" class="pr-0 pr-sm-3 pr-md-3 pr-lg-3">
                      {{ payment_method.brand }} **** {{ payment_method.last4 }}
                    </v-col>
                    <v-col cols="12" sm="4" md="4" class="pl-0 pl-sm-3 pl-sm-3 pl-lg-3">
                      {{ $t("signedin.Expires") }} {{ payment_method.exp_month }} / {{ payment_method.exp_year }}
                    </v-col>
                    <v-col cols="12" sm="4" md="4" class="text-right pl-0 pl-sm-3 pl-sm-3 pl-lg-3">
                      <v-tooltip bottom>
                        <template v-slot:activator="{on, attrs}">
                          <v-icon dark v-bind="attrs" v-on="on" class="remove-icon" @click="deleteCard()">
                            mdi-trash-can
                          </v-icon>
                        </template>
                        <span>{{ $t("signedin.DeleteCardOnly") }}</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </div>
                <div class="text-right mobile-center">
                  <v-tooltip bottom>
                    <template v-slot:activator="{on, attrs}">
                      <button v-if="!has_card" v-bind="attrs" v-on="on" id="addCardBtn" @click.prevent="">
                        <v-btn block @click="obtainConsent()" :disabled="!validateCardInputs || !has_billing_info"
                               class="primary-btn py-5 ma-auto">{{ $t("signedin.SaveCard") }}
                        </v-btn>
                      </button>
                    </template>
                    <span v-if="!has_billing_info">{{ $t("signedin.AddCardTooltip1") }}</span>
                    <span v-if="!validateCardInputs && has_billing_info">{{ $t("signedin.AddCardTooltip2") }}</span>
                    <span v-if="validateCardInputs && has_billing_info">{{ $t("signedin.AddCardTooltip3") }}</span>
                  </v-tooltip>
                </div>
              </div>
              <div class="text-left" v-if="has_billing_info && !editing_address">
                <v-divider class="mt-5 mb-3"/>
                <div class="mt-5">
                  <p class="font-weight-light">{{ $t("signedin.DeletePaymentInfo") }}</p>
                  <div class="mobile-center">
                    <v-btn @click="removePaymentMethod()" class="secondary-btn">
                      {{ $t("signedin.RemoveWholePaymentMethod") }}
                    </v-btn>
                  </div>
                </div>
              </div>
            </form>
          </v-tab-item>
          <v-tab-item class="mt-8">
          <v-simple-table class="billing-history-table">
            <template v-slot:default>
              <thead>
              <tr class="text-left">
                <th>
                  {{ $t( "signedin.BillingDate" ) }}
                </th>
                <th>
                  {{ $t( "signedin.Amount" ) }}
                </th>
                <th>
                  {{ $t( "signedin.UsersAmount" ) }}
                </th>
                <th>
                  {{ $t( "signedin.Duration" ) }}
                </th>
                <th>
                  {{ $t( "signedin.ReferNumber" ) }}
                </th>
                <th>
                  {{ $t( "signedin.Invoice" ) }}
                </th>
                <th>
                  {{ $t( "signedin.Action" ) }}
                </th>
              </tr>
              </thead>
              <tbody class="text-left">
                <tr class="billing-history-table-tr" :class="billingHistory.status" v-for="billingHistory in invoices" v-if="billingHistory.lines.data[0]
                && (billingHistory.status === 'paid' || ( billingHistory.status === 'open' && has_card && subscription.pending_subscription ) ) && billingHistory.lines.data[0].amount !== 0">
                  <td>{{ util.getDateFromTimestamp(billingHistory.created) }}</td>
                  <td>{{ ((billingHistory.amount_remaining + billingHistory.amount_paid) / 100).toFixed(2) }}€</td>
                  <td>{{ billingHistory.lines.data[0].quantity }}</td>
                  <td>{{ util.getDatePeriod( billingHistory.lines.data[0].period.start, billingHistory.lines.data[0].period.end ) }}</td>
                  <td>{{ billingHistory.number }}</td>
                  <td>
                    <a v-if="billingHistory.status === 'paid'" :href="billingHistory.invoice_pdf" target="_blank"
                       rel="noopener">{{ $t("signedin.PaymentStatusPaid") }}</a>
                    <a v-if="billingHistory.status === 'open'" :href="billingHistory.invoice_pdf" target="_blank"
                       rel="noopener">{{ $t("signedin.PaymentStatusPending") }}</a>
                  </td>
                  <td>
                    <a v-if="billingHistory.status === 'open'" :href="billingHistory.hosted_invoice_url" target="_blank"
                       rel="noopener">{{ $t("signedin.PayOnline") }}</a>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
            <v-btn class="primary-btn mt-8" @click="loadMoreBilling()" v-if="has_more_billing">
              {{ $t( "signedin.LoadMore" ) }}
            </v-btn>
        </v-tab-item>
          <v-tab-item class="mt-8 text-left">
            <div>
                <p class="font-weight-bold">{{ $t( "signedin.PaymentInfo" ) }}</p>
                <p>{{ $t( "signedin.PaymentExplanation1" ) }}</p>
                <p v-html="$t( 'signedin.PaymentExplanation2' )"></p>
                <p v-html="$t( 'signedin.PaymentExplanation3' )"></p>
                <p v-html="$t( 'signedin.PaymentExplanation4' )"></p>
            </div>
          </v-tab-item>
        </v-tabs>
      </div>
    </v-responsive>
    <v-dialog v-model="consent_dialog" width="auto ">
      <v-card style="max-width: 900px;">
        <v-card-title>
          <b>{{ $t( "signedin.ConsentDialogTitle" ) }}</b>
          <v-spacer/>
          <v-icon @click="obtainConsent()">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <p>
          </p>
          <v-divider class="ma-2"/>
          <div class="text-left">
            <p>{{ $t( "signedin.ConsentText1" ) }}</p>
            <p>{{ $t( "signedin.ConsentText2" ) }}</p>
            <ul class="consentUl">
              <li>{{ $t( "signedin.ConsentEmailBullet1" ) }}</li>
              <li>{{ $t( "signedin.ConsentEmailBullet2" ) }}</li>
              <li>{{ $t( "signedin.ConsentEmailBullet3" ) }}</li>
            </ul>
            <p>{{ $t( "signedin.ConsentText3" ) }}</p>
            <p>{{ $t( "signedin.ConsentText4" ) }}</p>
            <v-checkbox class="consentCheckBox" v-model="checkbox_read" :label="$t( 'signedin.ConsentCheckBoxLabel1' )"></v-checkbox>
            <v-checkbox class="consentCheckBox" v-model="checkbox_agree_recurring_payment" :label="$t( 'signedin.ConsentCheckBoxLabel2' )"></v-checkbox>
          </div>
          <v-divider class="ma-2"/>
          <div class="text-right">
            <button @click.prevent="">
              <v-btn block @click="addCard()" :disabled="!checkbox_read || !checkbox_agree_recurring_payment" class="primary-btn py-5 ma-auto">{{ $t( "signedin.Agree" ) }}</v-btn>
            </button>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
    import util from "../../../util/util";
    import countriesJson from '../../../assets/json/StripeCountries.json';
    import localesJson from '../../../assets/json/StripeLocales.json';
    const CARD_ELEMENT_OPTIONS = {
        style: {
            base: {
                color: "#32325d",
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSmoothing: "antialiased",
                fontSize: "16px",
                "::placeholder": {
                    color: "#aab7c4",
                }
            },
            invalid: {
                color: "#fa755a",
                iconColor: "#fa755a",
            }
        }
    };
    const sh_locales = {
        en : "en-US",
        fi : "fi-FI",
        de : "de-DE",
        sv : "sv-SE",
        pl : "pl-PL",
        nl : "nl-NL",
        fr : "fr-FR",
        es : "es-ES"
    };
    export default {
        name : "SubscriptionPane",
        data() {
            return {
                cardNumberValid : false,
                cardExpiryValid : false,
                cardCvcValid : false,
                countriesJson: this.sortCountriesByLetter( countriesJson ),
                localesList: this.sortLocalesByLetter( localesJson[ util.getLocale() ] ),
                is_working : true,
                payment_method : {},
                editing_address : false,
                has_card : false,
                has_billing_info : false,
                has_open_invoices : false,
                original_billing_info : {},
                preferred_locales: "",
                address_country : "",
                address_line1 : "",
                address_line2 : "",
                address_city : "",
                address_state : "",
                address_postal_code : "",
                email : $cc_user_info.email,
                card_name : $cc_user_info.name,
                consumers_supported : true,
                is_business_account : false,
                tax_id : "",
                tax_type : "",
                sortedTaxTypes : [],
                overWriteTaxId : true,
                invoices : [],
                billing_cursor : "",
                has_more_billing : false,
                consent_dialog : false,
                checkbox_read : false,
                checkbox_agree_recurring_payment : false,
                estimated_billing : "",
                subscription : $cc_user_info.subscription,
                util : util,
                paymentInfoSelect : true,
                billingHistorySelect : "",
                aboutSelect : "",
                tabStatus : 'payment_info'
            }
        },
        methods : {
            validateEmail : util.validateEmail,
            /**
             * Show/hide obtain consent dialog.
             */
            obtainConsent: function()
            {
                if( !this.consent_dialog )
                {
                    this.checkbox_read = false;
                    this.checkbox_agree_recurring_payment = false;
                }
                this.consent_dialog = !this.consent_dialog;
            },
            /**
             * Retrieves my subscription information: payment method and billing history.
             */
            retrieveMySubscriptionInfo : function()
            {
                Promise.all( [
                    this.$store.shioCoreClient.retrievePaymentMethod( $cc_user_info.organization.uuid ),
                    this.$store.shioCoreClient.retrieveBillingHistory( $cc_user_info.organization.uuid, "" )
                ] ).then( r =>
                {
                    const default_pms = ( this.subscription.default_pm !== null )
                    ? r[ 0 ].data.filter( entity => ( entity.id === this.subscription.default_pm ) )  :  r[ 0 ].data;
                    if( default_pms.length > 0 )
                    {
                        this.updateCard( r[0].data, default_pms[0], r[0].taxObject );
                    }
                    else
                    {
                        this.email = $cc_user_info.email;
                        this.renderStripeElements();
                    }
                    this.updateBillingInfo( r[ 0 ].taxObject.data[ 0 ] );
                    if( r[1].data.length > 0 )
                    {
                        this.invoices = r[ 1 ].data;
                        this.has_open_invoices = ( this.invoices.filter( invoice => invoice.status === 'open' ).length > 0 );
                        this.billing_cursor = r[ 1 ].data[ r[ 1 ].data.length - 1 ].id;
                        this.has_more_billing = r[ 1 ].has_more;
                    }
                    this.is_working = false;
                } ).catch( e =>
                {
                    this.$error( this.$t( 'message.Error!Unexpected' ) );
                } );
            },
            /**
             * Loads more billing history when there is more.
             */
            loadMoreBilling : function()
            {
                this.$store.shioCoreClient.retrieveBillingHistory( $cc_user_info.organization.uuid, this.billing_cursor )
                .then( r =>
                {
                    this.invoices = this.invoices.concat( r.data );
                    this.billing_cursor = r.data[ r.data.length - 1 ].id;
                    this.has_more_billing = r.has_more;
                } ).catch( e =>
                {
                    this.$error( this.$t( 'message.Error!Unexpected' ) );
                } );
            },
            /**
             * Removes current payment method.
             */
            removePaymentMethod()
            {
                const code = ( "" + ( Math.random() * 1000000000)).substring( 1, 5 );
                const text = ( this.$t( "signedin.ConfirmDeletePaymentMethod" ) + '\n\n'
                               + this.$t( "signedin.toProceedEnterCode" ) + code );
                const acceptText = this.$t( "signedin.Confirm" );
                const cancelText = this.$t( "signedin.Cancel" );
                this.$prompt( { text, acceptText, cancelText } ).then( r =>
                {
                    if( r === code )
                    {
                        this.is_working = true;
                        this.$store.shioCoreClient.deletePaymentMethod( $cc_user_info.organization.uuid,
                        this.payment_method.all_pm_ids || [], this.tax_object_id  ).then( r =>
                        {
                            window.location.reload();
                        } ).catch( e =>
                        {
                            this.is_working = false;
                            this.$error( this.$t( 'message.Error!Unexpected' ) );
                        } );
                    }
                    else
                    {
                        this.$warn( this.$t( "signedin.numbersDidNotMatch" ) );
                    }
                } ).catch( e =>
                {
                    this.is_working = false;
                } );
            },
            /**
             * Update payment method info.
             *
             * @param pms
             * @param pm
             */
            updateCard( pms, pm )
            {
                this.has_card = true;
                this.card_name = pm.billing_details.name;
                this.payment_method.last4 = pm.card.last4;
                this.payment_method.brand = pm.card.brand;
                this.payment_method.exp_month = pm.card.exp_month;
                this.payment_method.exp_year = pm.card.exp_year;
                this.payment_method.id = pm.id;
                this.payment_method.all_pm_ids = pms.map( pm => pm.id );
            },
            /**
             * Update billing info.
             *
             * @param taxObjectData
             */
            updateBillingInfo( taxObjectData )
            {
                if( this.subscription.preferred_locales &&  this.subscription.preferred_locales.length > 0 )
                {
                    this.preferred_locales = this.subscription.preferred_locales[ 0 ];
                }
                else
                {
                    // Set default locale for locale selector.
                    this.preferred_locales = ( !this.subscription.address )
                    ? sh_locales[ util.getLocale() ] : "en-US";
                }
                if( !this.subscription.address )
                {
                    this.subscription.address = {};
                }
                if( this.subscription.address.country )
                {
                    this.has_billing_info = true;
                }
                this.email = this.subscription.email;
                this.address_country = this.subscription.address.country;
                this.address_postal_code = this.subscription.address.postal_code;
                this.address_city = this.subscription.address.city;
                this.address_line1 = this.subscription.address.line1;
                this.address_line2 = this.subscription.address.line2;
                //this.address_state = this.subscription.address.state;
                this.prefillTaxTypes();
                this.is_business_account = !!taxObjectData;
                this.tax_type = taxObjectData ? taxObjectData.type : "";
                this.tax_id = taxObjectData ? taxObjectData.value : "";
                this.tax_object_id = taxObjectData ? taxObjectData.id : "";
                this.original_billing_info = {
                    email : this.email,
                    preferred_locales : this.preferred_locales,
                    address_country : this.address_country,
                    address_postal_code : this.address_postal_code,
                    address_city : this.address_city,
                    address_line1 : this.address_line1,
                    address_line2 : this.address_line2,
                    //address_state : this.address_state,
                    is_business_account : this.is_business_account,
                    tax_type : this.tax_type,
                    tax_id : this.tax_id,
                    tax_object_id : this.tax_object_id
                };
            },
            /**
             * Update payment method info.
             *
             */
            renderStripeElements()
            {
                setTimeout( function()
                {
                    if( typeof Stripe === 'undefined' )
                    {
                        this.renderStripeElements();
                    }
                    else
                    {
                        this.stripe = Stripe( process.env.VUE_APP_STRIPE_PUBLIC_KEY );
                        if( this.cardNumber )
                        {
                            this.cardNumber.clear();
                            this.cardExpiry.clear();
                            this.cardCvc.clear();
                        }
                        this.cardElements = this.stripe.elements();
                        this.cardNumber = this.cardElements.create( 'cardNumber',{ showIcon : true, style: CARD_ELEMENT_OPTIONS } );
                        this.cardExpiry = this.cardElements.create( 'cardExpiry',{ style: CARD_ELEMENT_OPTIONS } );
                        this.cardCvc = this.cardElements.create( 'cardCvc',{  showIcon : true, style: CARD_ELEMENT_OPTIONS } );
                        this.cardNumber.mount( this.$refs[ "cardNumber" ] );
                        this.cardExpiry.mount( this.$refs[ "cardExpiry" ] );
                        this.cardCvc.mount( this.$refs[ "cardCvc" ] );
                        this.cardNumber.addEventListener( 'change', ( event ) => {
                            this.cardNumberValid = event.complete;
                        });
                        this.cardExpiry.addEventListener( 'change', ( event ) => {
                            this.cardExpiryValid = event.complete;
                        });
                        this.cardCvc.addEventListener( 'change', ( event ) => {
                            this.cardCvcValid = event.complete;
                        });
                    }
                }.bind( this ), 100 );
            },
            /**
             *  Sort countries by country name alphabetically.
             *
             *  @param countries {Object[]}
             *  @returns {Object[]}
             */
            sortCountriesByLetter( countries )
            {
                return countries.sort( ( a, b ) => this.$t( "signedin.Country!" + a.country ).localeCompare( this.$t( "signedin.Country!" + b.country ), undefined, { caseFirst: "upper" } ) );
            },
            /**
             *  Sort locales by locale name alphabetically.
             *
             *  @param locales {Object[]}
             *  @returns {Object[]}
             */
            sortLocalesByLetter( locales )
            {
                return locales.sort( ( a, b ) => a.name.localeCompare( b.name, undefined, { caseFirst: "upper" } ) );
            },
            /**
             * Update tax id overwrite status.
             *
             */
            onTaxIdChange()
            {
                this.overWriteTaxId = !( this.tax_id && this.tax_id.length > 2 );
            },
            onBusinessAccountChange()
            {
                if( !this.is_business_account )
                {
                    this.tax_type = "";
                    this.tax_id = "";
                }
                else
                {
                    this.tax_type = this.sortedTaxTypes[ 0 ].enum;
                    this.tax_id = this.sortedTaxTypes[ 0 ].tax_code;
                }
            },
            prefillTaxTypes()
            {
                let taxTypes = [];
                this.countriesJson.find( country => { if ( country.code === this.address_country )
                {
                    this.consumers_supported = country.consumers_supported;
                    country.tax_types.forEach( type =>
                    {
                        type.code = country.code;
                        type.tax_code = country.tax_code;
                        taxTypes = country.tax_types;
                    } );
                } } );
                taxTypes = taxTypes.sort( ( a, b ) => a.desc.localeCompare( b.desc, undefined, { caseFirst: "upper" } ) );
                if( this.address_country === 'GB' && taxTypes[0].enum !== 'gb_vat' )
                {
                    [ taxTypes[ 0 ], taxTypes[ 1 ] ] = [ taxTypes[ 1 ], taxTypes[ 0 ] ];
                }
                if( this.address_country )
                {
                    this.tax_type = taxTypes[ 0 ].enum;
                    if( this.overWriteTaxId )
                    {
                        this.tax_id = taxTypes[ 0 ].tax_code;
                    }
                    if( !this.consumers_supported )
                    {
                        this.is_business_account = true;
                    }
                }
                this.sortedTaxTypes = taxTypes;
            },
            /**
             * Start to edit address.
             */
            editAddress()
            {
                this.editing_address = true;
            },
            /**
             * Stop editing address and revert billing values.
             */
            cancelEditBillingInfo()
            {
                this.editing_address = false;
                this.email = this.original_billing_info.email;
                this.preferred_locales = this.original_billing_info.preferred_locales;
                this.is_business_account = this.original_billing_info.is_business_account;
                this.tax_id = this.original_billing_info.tax_id;
                this.tax_type = this.original_billing_info.tax_type;
                this.address_country = this.original_billing_info.address_country;
                this.address_line1 = this.original_billing_info.address_line1;
                this.address_line2 = this.original_billing_info.address_line2;
                this.address_postal_code = this.original_billing_info.address_postal_code;
                //this.address_state = this.original_billing_info.address_state;
                this.address_city = this.original_billing_info.address_city;
                const country = this.countriesJson.find( country => country.code === this.address_country );
                this.consumers_supported = country && country.consumers_supported;
            },
            /**
             * Save billing information.
             */
            saveBillingInformation()
            {
                this.is_working = true;
                const taxIdChanged = this.is_business_account !== this.original_billing_info.business_account
                    || this.is_business_account && this.tax_id !== this.original_billing_info.tax_id;
                this.$store.shioCoreClient.updateBillingInformation(
                $cc_user_info.organization.uuid,
                {
                    email : this.email,
                    tax_object_id : taxIdChanged ? this.tax_object_id : "",
                    old_tax_id : taxIdChanged ? this.original_billing_info.tax_id : "",
                    old_tax_type : taxIdChanged ? this.original_billing_info.tax_type : "",
                    tax_type : this.is_business_account ? this.tax_type : "",
                    tax_id : this.is_business_account ? this.tax_id : "",
                    preferred_locales : this.preferred_locales || "",
                },
                {
                    postal_code : this.address_postal_code || "",
                    city : this.address_city || "",
                    line1 : this.address_line1 || "",
                    line2 : this.address_line2 || "",
                    state : this.address_state || "",
                    country : this.address_country || ""
                } ).then( r =>
                {
                    util.retrieveSubscription( this.$store.shioCoreClient,
                    $cc_user_info.organization.display_name ).then( () =>
                    {
                        this.subscription = $cc_user_info.subscription;
                        this.$inform( this.$t( "message.BillingInformationSaved" ) );
                        this.is_working = false;
                        this.has_billing_info = true;
                        this.editing_address = false;
                        const taxObjectData = { value : this.tax_id, type: this.tax_type, id : ( r.length > 0 ) ? r[ 0 ].id : this.tax_object_id };
                        this.updateBillingInfo( this.is_business_account ? taxObjectData : "" );
                        this.retrieveNextBilling();
                    } ).catch( e =>
                    {
                        this.is_working = false;
                        this.$error( this.$t( 'message.Error!Unexpected' ) );
                    } );
                } ).catch( e =>
                {
                    this.is_working = false;
                    if( this.refreshed_tax_object_id )
                    {
                        this.tax_object_id = this.refreshed_tax_object_id;
                    }
                    if( e.code )
                    {
                        this.$error( this.$t( 'message.Error!' + e.code ) );
                    }
                    else
                    {
                        this.$error( this.$t( 'message.Error!Unexpected' ) );
                    }
                } );
            },
            /**
             * Add credit card.
             */
            addCard()
            {
                if( !this.checkbox_read || !this.checkbox_agree_recurring_payment )
                {
                    return;
                }
                this.consent_dialog = false;
                this.is_working = true;
                this.stripe.createPaymentMethod({
                    type: 'card',
                    card : this.cardNumber,
                    billing_details: {
                        name: this.card_name,
                        email: this.email,
                        address : {
                            country : this.address_country || "",
                            postal_code : this.address_postal_code || "",
                            city : this.address_city || "",
                            line1 : this.address_line1 || "",
                            line2 : this.address_line2 || "",
                            state : this.address_state || ""
                        }
                    }
                }).then( r =>
                {
                    this.$store.shioCoreClient.createCard( $cc_user_info.organization.uuid,
                    r.paymentMethod.id, this.card_name ).then( r =>
                    {
                        this.$inform( this.$t( "message.CreditCardSaved" ) );
                        if( this.subscription.status === 'canceled' || this.subscription.status === 'incomplete_expired' )
                        {
                            this.$confirm( {
                                text : this.$t( "signedin.ConfirmCreateSubNow" ),
                                acceptText : this.$t( "signedin.CreateSubscriptionNow" ),
                                cancelText : this.$t( "signedin.CreateSubscriptionLater" )
                            } ).then( r =>
                            {
                                this.createNewPlan();
                            } ).catch( e =>
                            {
                                util.retrieveSubscription( this.$store.shioCoreClient,
                                $cc_user_info.organization.display_name ).then( r =>
                                {
                                    this.subscription = $cc_user_info.subscription;
                                    this.retrieveMySubscriptionInfo();
                                } ).catch( e =>
                                {
                                    this.is_working = false;
                                    this.$error( this.$t( 'message.Error!Unexpected' ) );
                                } );
                            } );
                        }
                        else
                        {
                            util.retrieveSubscription( this.$store.shioCoreClient,
                            $cc_user_info.organization.display_name ).then( r =>
                            {
                                this.subscription = $cc_user_info.subscription;
                                this.retrieveMySubscriptionInfo();
                            } ).catch( e =>
                            {
                                this.is_working = false;
                                this.$error( this.$t( 'message.Error!Unexpected' ) );
                            } );
                        }
                    } ).catch( e =>
                    {
                        this.is_working = false;
                        if( e.code )
                        {
                            this.$error( this.$t( 'message.Error!' + e.code ) );
                        }
                        else
                        {
                            this.$error( this.$t( 'message.Error!Unexpected' ) );
                        }
                    } );
                } ).catch( e =>
                {
                    this.is_working = false;
                    this.$error( this.$t( 'message.Error!Unexpected' ) );
                } );
            },
            /**
             * Delete card.
             */
            deleteCard()
            {
                this.$store.shioCoreClient.deleteCard( $cc_user_info.organization.uuid, this.payment_method.all_pm_ids  ).then( r =>
                {
                    window.location.reload();
                } ).catch( e =>
                {
                    this.is_working = false;
                    this.$error( this.$t( 'message.Error!Unexpected' ) );
                } );
            },
            /**
             * Cancel current plan.
             */
            cancelCurrentPlan()
            {
                const code = ( "" + ( Math.random() * 1000000000)).substring( 1, 5 );
                const text = ( this.$t( "signedin.CancelPlanConfirm" ) + '\n\n'
                               + this.$t( "signedin.toProceedEnterCode" ) + code );
                const acceptText = this.$t( "signedin.Confirm" );
                const cancelText = this.$t( "signedin.Cancel" );
                this.$prompt( { text, acceptText, cancelText } ).then( r =>
                {
                    if( r === code )
                    {
                        this.is_working = true;
                        this.$store.shioCoreClient.updateSubscriptionPlan( $cc_user_info.organization.uuid, false ).then( r =>
                        {
                            window.location.reload();
                        } ).catch( e =>
                        {
                            this.is_working = false;
                            this.$error( this.$t( 'message.Error!Unexpected' ) );
                        } );
                    }
                    else
                    {
                        this.$warn( this.$t( "signedin.numbersDidNotMatch" ) );
                    }
                } ).catch( e =>
                {
                    this.is_working = false;
                } );
            },
            /**
             * Create new plan.
             */
            createNewPlan()
            {
                const text = this.$t( "signedin.CreatePlanConfirm" );
                const acceptText = this.$t( "signedin.Confirm" );
                const cancelText = this.$t( "signedin.Cancel" );
                this.$confirm( { text, acceptText, cancelText } ).then( r =>
                {
                    this.is_working = true;
                    this.$store.shioCoreClient.updateSubscriptionPlan( $cc_user_info.organization.uuid, true ).then( r =>
                    {
                        window.location.reload();
                    } ).catch( e =>
                    {
                        this.is_working = false;
                        this.$error( this.$t( 'message.Error!Unexpected' ) );
                    } );
                } ).catch( e =>
                {
                    this.is_working = false;
                } );
            },
            /**
             * Retrieves upcoming invoice.
             */
            retrieveNextBilling()
            {
                if( this.subscription.has_trial_period || this.subscription.has_valid_subscription
                                              || this.subscription.has_payment_past_due || this.subscription.incomplete_subscription )
                {
                    this.$store.shioCoreClient.retrieveUpcomingInvoice( util.getOrganizationId() ).then( r =>
                    {
                        const nextBillingDate = util.getDateFromTimestamp( r.next_payment_attempt );
                        const nextBillingValue = ( r.amount_due / 100 ).toFixed(2);
                        this.estimated_billing = eval( '`' + this.$t( "signedin.EstimatedBilling" ) + '`' );
                    } ).catch( e => this.$error( this.$t( "signedin.Error!Unexpected" ) ) );
                }
            },
            /**
             * Tabs style on mobile phone devices.
             */
            goToPaymentInfo()
            {
              this.paymentInfoSelect = true;
              this.billingHistorySelect = false;
              this.aboutSelect = false;
            },
            goToBillingHistory()
            {
              this.billingHistorySelect = true;
              this.paymentInfoSelect = false;
              this.aboutSelect = false;
            },
            goToAbout()
            {
              this.aboutSelect = true;
              this.paymentInfoSelect = false;
              this.billingHistorySelect = false;
            }
        },
        mounted()
        {
            if( typeof Stripe === 'undefined' )
            {
                const stripeScript = document.createElement( "script" );
                stripeScript.setAttribute( "src", process.env.VUE_APP_STRIPE_SCRIPT_URL );
                document.head.appendChild( stripeScript );
            }
            this.renderStripeElements();
            this.retrieveNextBilling();
            this.retrieveMySubscriptionInfo();
        },
        computed :
        {
            isBillingInfoOrig()
            {
                return     ( ( this.email === this.original_billing_info.email )
                          && ( this.preferred_locales === this.original_billing_info.preferred_locales )
                          && ( this.address_line1 === this.original_billing_info.address_line1 )
                          && ( this.address_line2 === this.original_billing_info.address_line2 )
                          && ( this.address_postal_code === this.original_billing_info.address_postal_code )
                          && ( this.address_country === this.original_billing_info.address_country )
                          && ( this.address_city === this.original_billing_info.address_city )
                          //&& ( this.address_state === this.original_billing_info.address_state )
                          && ( this.is_business_account === this.original_billing_info.is_business_account )
                          && ( !this.is_business_account || this.tax_id === this.original_billing_info.tax_id )
                          && ( this.email === this.original_billing_info.email ) );
            },
            validateBillingInfoInputs()
            {
                return   ( ( this.email && this.email.length > 0 )
                           && ( this.address_country && this.address_country.length > 0 )
                           && ( this.address_postal_code && this.address_postal_code.length > 0 )
                           && ( this.address_line1 && this.address_line1.length > 0 )
                           && ( this.address_city && this.address_city.length > 0 )
                           && ( this.preferred_locales && this.preferred_locales.length > 0 )
                           && ( !this.is_business_account || this.tax_id && this.tax_id.length > 2 )
                           && ( !this.is_business_account || ( this.tax_id.substring( 0, 2 ) === 'FI' &&  this.address_country === 'FI' )
                                || ( this.tax_id.substring( 0, 2 ) !== 'FI' &&  this.address_country !== 'FI' ) )
                           && this.validateEmail( this.email ) === true );
            },
            validateCardInputs()
            {
                return !!( this.cardExpiryValid
                           && this.cardNumberValid
                           && this.cardCvcValid
                           && this.card_name );
            }
        }
    }
</script>

<style lang="sass" scoped>
  @import "../../../styles/style"

  .payment-form
    min-width: 400px
    align-self: center
    border-radius: 6px
    padding: 0

  .invoice-link
    color: $text-black !important
    text-decoration: underline

  .v-tab.v-tab--active
    color: $vivid-pink !important

  .tab-title
    text-transform: none
    font-size: 20px
    color: $text-black !important
    letter-spacing: normal

  .inputForm
    border-radius: 6px
    margin-bottom: 12px
    padding: 12px
    border: 1px solid rgba(50, 50, 93, 0.1)
    height: 48px
    font-size: 16px
    width: 100%
    background: white

  .zip-code
    border-radius: 0 0 6px 6px

  .card-element
    border-radius: 4px
    padding: 18px 12px 18px 12px
    border: 1px solid rgba(0, 0, 0, 0.42)
    height: 56px
    width: 100%
    background: white
    margin-bottom: 30px

  .card-element-second-line-left, .card-element-second-line-right
    padding: 18px 12px 18px 12px
    border: 1px solid rgba(0, 0, 0, 0.42)
    height: 56px
    width: 100%
    background: white
    margin-bottom: 30px

  .card-element-second-line-left
    border-radius: 4px

  .card-element-second-line-right
    border-radius: 4px

  .card-element.StripeElement.StripeElement--empty.StripeElement--focus,
  .card-element-second-line-left.StripeElement.StripeElement--empty.StripeElement--focus,
  .card-element-second-line-right.StripeElement.StripeElement--empty.StripeElement--focus
    border: 2px solid $vivid-pink

  .billing-history-table th, td
    font-size: 16px !important
    color: $text-black
    font-weight: normal

  .billing-history-table-tr.open td
    color: $vivid-pink !important

  .billing-history-table-tr.paid td a
    color: $text-black !important

  .v-data-table.billing-history-table
    border: 0

  .credit-card-info-title
    color: $dark-grey

  .billing-info-title
    color: $text-black
    padding-bottom: 30px

  .tax-info-explanation
    color: $text-black

  .saved-card-number
    color: rgba(0, 0, 0, 0.38)

  .overdue-alert-icon
    font-size: 20px
    color: $vivid-pink

  .incomplete-alert-icon
    font-size: 20px
    color: $vivid-pink !important

  .remove-icon
    font-size: 20px
    color: $vivid-pink
    cursor: pointer

  .card-div
    border: 1px solid #ddd
    border-radius: 4px
    padding: 15px
    color: rgba(0, 0, 0, 0.38)

  @media (max-width: 599px)
    .mobile-center, .cancel-plan-btn
      text-align: center !important

    button
      width: 100%

    button.v-icon.remove-icon
      width: initial !important

  .consentUl
    margin-bottom: 16px

  .consentCheckBox
    color : black
    margin-top : 0 !important
    margin-left : -25px

  .subscription-title-div
    font-size: 16px
    font-weight: bold
    margin-top: 20px

  .subscription-info
    margin-bottom: 0

  .subscription-status-div
    display: inline
    padding: 2px 5px 2px 5px
    border-radius: 4px
    font-size: 15px
    font-weight: normal

  .trialing
    background-color: #ddfffe

  .canceled, .incomplete_expired
    background-color: #f2f2f2

  .active
    background-color: #D7F7C2

  .incomplete, .past_due
    background-color: #FFE7F2
</style>

<style lang="sass">
  #addBillingInfoBtn .v-btn
    max-height: 70px

  #addBillingInfoBtn span.v-btn__content
    white-space: normal
    flex: auto

  .v-input.select-tax-id-type input
    max-height: 0
    padding: 0

  @media (max-width: 599px)
    #addBillingInfoBtn .v-btn
      height: 100% !important

    .subscription-tabs .v-slide-group__prev.v-slide-group__prev--disabled
      display: none !important

    .payment-form
      min-width: auto !important

    .select-country .v-text-field__details, .select-tax-id-type .v-text-field__details
      min-height: auto
      margin-bottom: 0 !important
</style>
