<template>
  <v-container class="pt-4 maxWidth-1100">
    <v-overlay v-if="loading">
      <v-progress-circular size="48" indeterminate/>
    </v-overlay>
    <h1 class="shio-section-title">{{ $t( "signedin.MyWallet" ) }}</h1>
    <v-responsive class="pt-5 px-4">
      <h3 class="text-left">{{ $t( "wallet.AvailableBalance" ) }}</h3>
      <div class="text-left subscription-title-div">
        <span> {{ group_balance }}</span>&nbsp;
      </div>
      <div>
        <v-row class="fill-height" no-gutters>
          <v-col cols="12" sm="6" md="6" class="text-left py-0">
            <button id="topUpBalance" @click.prevent="" style="margin-top: 20px" ref="button">
              <v-btn @click="topUpWallet" class="primary-btn py-3 ma-auto" block>
                {{ $t( "wallet.TopUp" ) }}
              </v-btn>
            </button>
          </v-col>
        </v-row>
      </div>
      <br/>
      <br/>
      <h3 class="text-left">{{ $t( "wallet.Transactions" ) }}</h3>
      <div>
        <v-row>
          <v-col cols="2">
            <v-menu
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    :value="formatFromDate"
                    :label="$t( 'signedin.From' )"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on">
                </v-text-field>
              </template>
              <v-date-picker
                  class="from-date"
                  v-model="from_date"
                  :locale="fullLocale"
                  :first-day-of-week="1"
                  @input="resetTransactions();"
                  :max="new Date().toISOString().substr(0, 10)"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="2">
            <v-menu
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    :value="formatToDate"
                    :label="$t( 'signedin.To' )"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                  class="to-date"
                  v-model="to_date"
                  :locale="fullLocale"
                  :first-day-of-week="1"
                  @input="resetTransactions();"
                  :max="new Date().toISOString().substr(0, 10)"
                  :min="from_date"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="3" style="margin-top: 18px">
            <v-autocomplete
                v-model="selected_transaction_titles"
                :items="localizedTransactionTypes"
                dense
                chips
                small-chips
                return-object
                :label="$t( 'wallet.SelectTransactionTypes' )"
                @change="resetTransactions()"
                multiple
            ></v-autocomplete>
          </v-col>
          <v-col cols="4">
            <v-text-field v-model="transaction_search" append-icon="mdi-magnify" :label="$t( 'wallet.SearchTransactions' )"
                          @change="resetTransactions()"/>
          </v-col>
          <v-col cols="1">
            <div class="text-left mx-4 mt-6">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <v-icon class="download-icon" medium color="black darken-4"
                            @click="downloadTransactions" :disabled="wallet_events.length === 0">mdi-download</v-icon>
                  </span>
                </template>
                <span>{{ $t( "wallet.DownloadTransactionDesc" ) }}</span>
              </v-tooltip>
            </div>
          </v-col>
        </v-row>
      </div>
      <div>
        <v-simple-table class="transactions-table">
          <template v-slot:default>
            <thead>
            <tr class="text-left">
              <th>
                {{ $t( "wallet.TransactionDate" ) }}
              </th>
              <th>
                {{ $t( "wallet.TransactionTitle" ) }}
              </th>
              <th class="text-right">
                {{ $t( "wallet.TransactionAmount" ) }}
              </th>
              <th class="text-right">
                {{ $t( "wallet.TransactionBalance" ) }}
              </th>
            </tr>
            </thead>
            <tbody class="text-left">
            <tr v-for="event in wallet_events">
              <td>{{ dateFormat( event.timestamp, "dd.mm.yyyy, HH:MM" ) }}</td>
              <td>
                <a @click.prevent="toggleTransactionDetailsDialog( event )" target="_blank" class="underline-link">
                  {{ $t("wallet.TransactionTitle!" + event.transaction_title) }}
                </a>
              </td>
              <td style="text-align: right">€{{ ( event.credit - event.debit ).toFixed( 2 ) }}</td>
              <td style="text-align: right">€{{ event.balance }}</td>
            </tr>
            <infinite-loading :identifier="transactionInfiniteId" @infinite="transactionInfiniteHandler">
              <div slot="no-more"></div>
              <div slot="no-results"></div>
              <v-progress-circular slot="spinner" size="48" indeterminate/>
            </infinite-loading>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </v-responsive>
    <v-dialog v-model="top_up_dialog" width="auto ">
      <v-card style="max-width: 900px;min-width: 900px;min-height: 700px;">
        <v-card-title>
          <b>{{ $t( "wallet.ChooseRechargeMethod" ) }}</b>
          <v-spacer/>
          <v-icon @click="closeTopUpDialog()">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <v-divider class="ma-2"/>
          <div class="text-left">
            <v-radio-group v-model="top_up_method" @change="resetTopup()">
              <v-radio :label="$t( 'wallet.BankTransfer' )" value="bank_transfer"></v-radio>
              <v-radio :label="$t( 'wallet.OnlinePayment' )" value="online_payment"></v-radio>
            </v-radio-group>
          </div>
          <div class="screenshotContainer" v-if="top_up_method ==='bank_transfer'">
            <p class="text-left">
              {{ $t( "wallet.BankTransferDesc" ) }}
            </p>
            <div class="text-left" style="border: 1px solid grey; padding: 10px">
              <v-icon>mdi-bank</v-icon>{{ $t( "wallet.IBAN" ) }}
              <v-row class="fill-height" no-gutters>
                <v-col cols="12" sm="2" md="2" class="text-left py-0">
                  <p class="text-left mt-2">{{ $t( "wallet.AccountHolder" ) }}</p>
                  <p class="text-left">{{ $t( "wallet.BICCode" ) }}</p>
                  <p class="text-left">{{ $t( "wallet.IBAN" ) }}</p>
                  <p class="text-left">{{ $t( "wallet.Country" ) }}</p>
                </v-col>
                <v-col cols="12" sm="10" md="10" class="text-left py-0">
                  <p class="text-left mt-2">{{ iban.account_holder_name }}</p>
                  <p class="text-left">{{ iban.bic }}</p>
                  <p class="text-left">{{ iban.iban }}</p>
                  <p class="text-left">{{ iban.country }}</p>
                </v-col>
              </v-row>
            </div>
          </div>
          <div class="screenshotContainer" v-if="top_up_method ==='online_payment' && !onlinePaying">
            <p class="text-left font-weight-bold">{{ $t( "wallet.AvailableBalanceTitle" ) }} {{ group_balance }}</p>
            <p class="text-left">{{ topUpAmountTitle }}</p>
            <v-text-field v-model="topUpValue" style="margin-left: -25px" type="number">
              <template v-slot:label>
                {{ $t( "wallet.EnterANumber" ) }}<span class="red&#45;&#45;text"> *</span>
              </template>
            </v-text-field>
          </div>
          <div class="screenshotContainer" v-if="top_up_method ==='online_payment' && onlinePaying">
            <p class="text-left">{{ $t( "wallet.YouArePaying" ) }} {{ topUpValue }}</p>
            <form id="payment-form">
              <div id="payment-element" ref="payment-element"></div>
              <div id="payment-message" class="hidden"></div>
            </form>
          </div>
          <div class="text-right">
            <button @click.prevent="">
              <v-btn block v-if="top_up_method ==='bank_transfer'" @click="closeTopUpDialog()" class="primary-btn py-5 ma-auto">{{ $t( "wallet.Done" ) }}</v-btn>
              <v-btn block v-if="top_up_method !=='bank_transfer' && top_up_method !=='' && !onlinePaying"
                     @click="createPaymentIntent()" class="primary-btn py-5 ma-auto"
              :disabled="!topUpValueValid">{{ $t( "wallet.Proceed" ) }}</v-btn>
              <v-btn block v-if="top_up_method !=='bank_transfer' && top_up_method !=='' && onlinePaying"
                     @click="confirmPayment()" class="primary-btn py-5 ma-auto"
                     :disabled="!topUpValueValid">{{ $t( "wallet.PayNow" ) }}</v-btn>
            </button>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="transaction_details_dialog" width="auto">
      <v-card>
        <v-card-title>
          <b>{{ $t( "wallet.TransactionDetails" ) }}</b>
          <v-spacer></v-spacer>
          <v-btn icon @click="toggleTransactionDetailsDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="text-left" style="min-height: 50vh;min-width: 50vh;">
          <v-divider/>
          <h4 class="font-weight-bold mt-1 mb-1">Title</h4>
          <span>{{ $t("wallet.TransactionTitle!" + selected_transaction_event.transaction_title) }}</span>
          <h4 class="font-weight-bold mt-1 mb-1">{{ $t( "wallet.TransactionDescription" ) }}</h4>
          <span v-if="selected_transaction_event.transaction_title === 'top-up-bank-transfer' || selected_transaction_event.transaction_title === 'top-up-online-payment'">
            {{ $t("wallet.TransactionDes!" + this.selected_transaction_event.transaction_title) }}
            €{{ ( selected_transaction_event.credit - selected_transaction_event.debit ).toFixed( 2 ) }}
          </span>
          <span v-if="selected_transaction_event.transaction_title !== 'top-up-bank-transfer' && selected_transaction_event.transaction_title !== 'top-up-online-payment'">
            {{ $t("wallet.TransactionDes!" + this.selected_transaction_event.transaction_title) }}
          </span>
          <h4 class="font-weight-bold  mt-1 mb-1">{{ $t( "wallet.Datetime" ) }}</h4>
          <span>{{ dateFormat( selected_transaction_event.timestamp, "dd.mm.yyyy, HH:MM" ) }}</span>
          <h4 class="font-weight-bold mt-1 mb-1">{{ $t( "wallet.Amount" ) }}</h4>
          <span>€{{ ( selected_transaction_event.credit - selected_transaction_event.debit ).toFixed( 2 ) }}</span>
          <h4 class="font-weight-bold mt-1 mb-1">{{ $t( "wallet.Balance" ) }}</h4>
          <span>€{{ selected_transaction_event.balance }}</span>
          <div v-if="selected_transaction_event.process_uuid && selected_transaction_event.process_uuid !== ''">
            <v-divider class="mt-2 mb-2"/>
            <h4 class="font-weight-bold">{{ $t( "wallet.ProcessUuid" ) }}</h4>
            <span>{{ this.selected_transaction_event.process_uuid }}</span>
            <h4 class="font-weight-bold mt-1 mb-1">{{ $t( "wallet.LastUpdatedBy" ) }}</h4>
            <span>{{ this.selected_transaction_event.username }}</span>
            <h4 class="font-weight-bold mt-1 mb-1">{{ $t( "wallet.Prepayment" ) }}</h4>
            <span>€{{ this.selected_transaction_event.prepayment }}</span>
            <div v-if="selected_transaction_event.transaction_title.indexOf( 'finalise-cost-process' ) !== -1">
              <h4 class="font-weight-bold mt-1 mb-1">{{ $t( "wallet.FinalCost" ) }}</h4>
              <span>€{{ this.selected_transaction_event.final_cost }}</span>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script src="checkout.js" defer></script>
<script src="https://js.stripe.com/v3/"></script>
<script>
    import util from "../../../util/util";
    import dateFormat from "dateformat";
    import {globalEvents} from "@cloudlace/client";
    import InfiniteLoading from 'vue-infinite-loading';
    const paginationLimit = 100;
    let onServerMessage;
    export default {
        name : "WalletPane",
        components : {
            InfiniteLoading
        },
        data() {
            const from = Date.now() - (30 * 24 * 3600 * 1000);
            const to = Date.now();
            return {
                groupId : $cc_user_info.organization.uuid,
                no_row : true,
                top_up_dialog : false,
                transaction_details_dialog : false,
                topUpValue: "",
                wallet_events : [],
                download_wallet_events : [],
                iban : {},
                top_up_method : "bank_transfer",
                client_secret : "",
                group_balance : 0.00,
                onlinePaying : false,
                selected_transaction_event : {},
                from_date : new Date( from ).toISOString().substring( 0, 10 ),
                to_date : new Date( to ).toISOString().substring( 0, 10 ),
                from_menu : false,
                to_menu : false,
                transaction_titles : [ "incoming", "outgoing" ],
                selected_transaction_titles : [ "incoming", "outgoing" ].map( type => ({
                    value: type,
                    text: this.$t("wallet.TransactionType!" + type )
                })),
                loading : true,
                transactionInfiniteId : 0,
                paginationFrom : 0,
                minTopupValue : process.env.VUE_APP_WALLET_MIN_ONLINE_TOPUP || 10000,
                transaction_search : "",
                util : util
            }
        },
        methods : {
            /**
             * Date formatter.
             */
            dateFormat : dateFormat,
            topUpWallet: function()
            {
                this.top_up_dialog = true;
            },
            closeTopUpDialog: function()
            {
                this.top_up_dialog = false;
                this.top_up_method = "bank_transfer";
                this.onlinePaying = false;
                this.topUpValue = "";
            },
            sortEventsByTimestamp( events )
            {
                return events.sort( ( a, b ) => a.timestamp > b.timestamp );
            },
            toggleTransactionDetailsDialog( event )
            {
                if( event )
                {
                    this.selected_transaction_event = event;
                }
                this.transaction_details_dialog = !this.transaction_details_dialog;
            },
            resetTransactions()
            {
                this.loading = true;
                this.wallet_events = [];
                this.paginationFrom = 0;
                this.transactionInfiniteId ++;
            },
            resetTopup()
            {
                this.onlinePaying = false;
                this.topUpValue = "";
            },
            transactionInfiniteHandler( $state )
            {
                this.loading = true;
                const selected_transaction_title_keys =  this.selected_transaction_titles.map( type => type.value );
                const props = {
                    incoming : selected_transaction_title_keys.includes( "incoming" ),
                    outgoing : selected_transaction_title_keys.includes( "outgoing" ),
                    limit : paginationLimit,
                    paginationFrom : this.paginationFrom,
                    from : this.from,
                    to : this.to,
                };
                if( this.transaction_search.length > 0 )
                {
                    props.transaction_search = this.transaction_search;
                }
                const currentFrom = this.paginationFrom;
                this._retrieveTransaction( props ).then( fetchedWalletEvents =>
                {
                    if( !currentFrom ) { this.wallet_events = []; }
                    if ( fetchedWalletEvents.length > 0 )
                    {
                        this.wallet_events.push( ...fetchedWalletEvents );
                        this.loading = false;
                        if ( fetchedWalletEvents.length < paginationLimit )
                        {
                            $state.complete();
                        }
                        else
                        {
                            this.paginationFrom = currentFrom + paginationLimit;
                            $state.loaded();
                        }
                    }
                    else
                    {
                        this.loading = false;
                        $state.complete();
                    }
                } ).catch( e =>
                {
                    this.$error( this.$t( 'message.Error!Unexpected' ) );
                } );
            },
            downloadTransactions()
            {
                this.loading = true;
                const selected_transaction_title_keys =  this.selected_transaction_titles.map( type => type.value );
                const props = {
                    incoming : selected_transaction_title_keys.includes( "incoming" ),
                    outgoing : selected_transaction_title_keys.includes( "outgoing" ),
                    limit : paginationLimit,
                    from : this.from,
                    to : this.to,
                };
                if( this.transaction_search.length > 0 )
                {
                    props.transaction_search = this.transaction_search;
                }
                const download_rows = [];
                this._retrieveDownloadTransactions( props ).then( wallet_events =>
                {
                    download_rows.push( this.downloadTransactionsHeaders.map( header => { return header.text} ).join( ',' ) );
                    for( let i = 0; i < wallet_events.length; i++ )
                    {
                        const values = this.downloadTransactionsHeaders.map( header =>
                        {
                            let value = wallet_events[ i ][ header.value ];
                            if( header.value === 'transaction_title' )
                            {
                                value = this.$t("wallet.TransactionTitle!" + value );
                            }
                            if( header.value === 'timestamp' )
                            {
                                value = this.dateFormat( value, "dd.mm.yyyy, HH:MM" );
                            }
                            if( !value && ( header.value === 'username' || header.value === 'process_uuid' ) )
                            {
                                value = "-";
                            }
                            let escaped = ( '' + value ).replace(/"/g, '\\"' );
                            return `"${escaped}"`;
                        });
                        download_rows.push( values.join( ',' ) );
                    }
                    const csvString = download_rows.join( '\n' );
                    const blob = new Blob([ csvString ], { type: 'text/csv' });
                    const url = URL.createObjectURL( blob );
                    const link = document.createElement( 'a' );
                    link.href = url;
                    link.download = this._formulateTransactionFileName();
                    link.click();
                    URL.revokeObjectURL( url );
                    this.loading = false;
                } ).catch( e =>
                {
                    this.$error( this.$t( 'message.Error!Unexpected' ) );
                } )
            },
            _retrieveTransaction( props )
            {
                return new Promise( ( resolve, reject ) =>
                {
                    this.$store.shioCoreClient.retrieveWalletEvents( this.groupId, props ).then( r =>
                    {
                        if ( r.entities.length )
                        {
                            const fetchedWalletEvents = r.entities.map( entity => {
                                entity.entity.debit = entity.entity.debit ? ( entity.entity.debit / 100 ).toFixed( 2 ) : "0.00";
                                entity.entity.credit = entity.entity.credit ? ( entity.entity.credit / 100 ).toFixed( 2 ) : "0.00";
                                entity.entity.prepayment = entity.entity.prepayment ? ( entity.entity.prepayment / 100 ).toFixed( 2 ) : "-";
                                entity.entity.balance = entity.entity.balance ? ( entity.entity.balance / 100 ).toFixed( 2 ) : "0.00";
                                entity.entity.amount = ( entity.entity.credit -  entity.entity.debit ).toFixed( 2 );
                                entity.entity.final_cost = entity.entity.final_cost ? ( entity.entity.final_cost  / 100 ).toFixed( 2 ): "-";
                                return entity.entity;
                            });
                            resolve( fetchedWalletEvents );
                        }
                        else
                        {
                            resolve( [] );
                        }
                    } ).catch( e =>
                    {
                        reject( e );
                    } );
                } );
            },
            _retrieveDownloadTransactions( props )
            {
                return new Promise( ( resolve, reject ) =>
                {
                    this._retrieveTransaction( props ).then( fetchedWalletEvents =>
                    {
                        if( !props.paginationFrom )
                        {
                            props.paginationFrom = 0;
                            this.download_wallet_events = [];
                        }
                        if ( fetchedWalletEvents.length > 0 )
                        {
                            this.download_wallet_events.push( ...fetchedWalletEvents );
                            if ( fetchedWalletEvents.length < paginationLimit )
                            {
                                resolve( this.download_wallet_events );
                            }
                            else
                            {
                                props.paginationFrom = props.paginationFrom + paginationLimit;
                                resolve( this._retrieveDownloadTransactions( props ) );
                            }
                        }
                        else
                        {
                            resolve( this.download_wallet_events );
                        }
                    } ).catch( e =>
                    {
                        this.$error( this.$t( 'message.Error!Unexpected' ) );
                        reject( e );
                    } );
                } );
            },
            _formulateTransactionFileName()
            {
                let fileName = 'transactions';
                this.selected_transaction_titles.map( title => {
                    fileName = fileName + "-" + title.text;
                });
                fileName = fileName + "-" + this.formatFromDate + "-" + this.formatToDate;
                if( this.transaction_search.length > 0 )
                {
                    fileName = fileName + "-" + this.transaction_search;
                }
                return fileName + '.csv';
            },
            retrieveWallet()
            {
                this.$store.shioCoreClient.retrieveWallet( this.groupId ).then( r =>
                {
                    this.group_balance = "€" + ( r.entity.balance / 100 ).toFixed( 2 );
                } ).catch( e =>
                {
                    this.$error( this.$t( 'message.Error!Unexpected' ) );
                } );
            },
            retrieveBankDetails()
            {
                this.$store.shioCoreClient.retrieveBankDetails( this.groupId ).then( r =>
                {
                    this.iban = r.bank_transfer?.financial_addresses[ 0 ]?.iban;
                } ).catch( e =>
                {
                    this.$error( this.$t( 'message.Error!Unexpected' ) );
                } );
            },
            createPaymentIntent()
            {
                this.$store.shioCoreClient.createPaymentIntent( this.groupId, this.topUpValue * 100 ).then( r =>
                {
                    this.client_secret = r.client_secret;
                    this.renderStripeElements();
                } ).catch( e =>
                {
                    this.$error( this.$t( 'message.Error!Unexpected' ) );
                } );
            },
            renderStripeElements()
            {
                this.onlinePaying = true;
                setTimeout( function()
                {
                    if( typeof Stripe === 'undefined' )
                    {
                        this.renderStripeElements();
                    }
                    else
                    {
                        this.stripe = Stripe( process.env.VUE_APP_STRIPE_PUBLIC_KEY );
                        if( this.payment )
                        {
                            this.payment.clear();
                        }
                        const clientSecret = this.client_secret;
                        const appearance = {
                            theme: 'stripe',
                        };
                        this.stripeElements = this.stripe.elements({ appearance, clientSecret });
                        const paymentElementOptions = {
                            layout: "tabs",
                        };
                        this.payment = this.stripeElements.create( "payment", paymentElementOptions );
                        this.payment.mount( "#payment-element" );
                    }
                }.bind( this ), 100 );
            },
            confirmPayment()
            {
                const root = process.env.VUE_APP_MAIN_DOMAIN_ADDRESS;
                const walletPageLink = root + "/#/wallet";
                const elements = this.stripeElements;
                this.stripe.confirmPayment({
                    elements,
                    confirmParams: {
                        return_url:  walletPageLink,
                    },
                }).then( r =>
                {
                    console.log( "Payment confirmed", r );
                } ).catch( e =>
                {
                    this.is_working = false;
                    this.$error( this.$t( 'message.Error!Unexpected' ) );
                } );
            },
            _onServerMessage( evt )
            {
                if( evt.detail.data_type === "wallet" )
                {
                    this.retrieveWallet();
                    this.resetTransactions();
                }
            }
        },
        computed : {
            downloadTransactionsHeaders()
            {
                return [ {
                    text: this.$t( 'wallet.Title' ),
                    value: 'transaction_title',
                }, {
                    text: this.$t( 'wallet.Datetime' ),
                    value: 'timestamp'
                }, {
                    text: this.$t( 'wallet.Amount' ),
                    value: 'amount'
                }, {
                    text: this.$t( 'wallet.Balance' ),
                    value: 'balance'
                }, {
                    text: this.$t( 'wallet.LastUpdatedBy' ),
                    value: 'username'
                }, {
                    text: this.$t( 'wallet.ProcessUuid' ),
                    value: 'process_uuid'
                }, {
                    text: this.$t( 'wallet.Prepayment' ),
                    value: 'prepayment'
                }, {
                    text: this.$t( 'wallet.FinalCost' ),
                    value: 'final_cost'
                }];
            },
            localizedTransactionTypes()
            {
                return this.transaction_titles.map( type => ({
                    value: type,
                    text: this.$t("wallet.TransactionType!" + type )
                }));
            },
            topUpValueValid()
            {
                return ( this.topUpValue >= ( this.minTopupValue / 100 ) );
            },
            fullLocale()
            {
                switch ( util.getLocale() )
                {
                    case "fi":
                        return "fi-FI";
                    case "sv":
                        return "sv-SE";
                    case "pl":
                        return "pl-PL";
                    case "de":
                        return "de-DE";
                    case "nl":
                        return "nl-NL";
                    case "fr":
                        return "fr-FR";
                    case "es":
                        return "es-ES"
                    default:
                        return "en-GB";
                }
            },
            formatFromDate()
            {
                return dateFormat( new Date( this.from_date ), "dd.mm.yyyy" );
            },
            formatToDate()
            {
                return dateFormat( new Date( this.to_date ), "dd.mm.yyyy" );
            },
            from()
            {
                return new Date( this.from_date + 'T00:00:00.000Z' ).getTime();
            },
            to()
            {
                return new Date( this.to_date + 'T23:59:59.999Z' ).getTime();
            }
        },
        mounted()
        {
            if( window.location.search )
            {
                const url = new URL(window.location.href);
                url.search = ''; // Clear all query parameters
                window.history.replaceState(null, '', url.toString());
            }
            if( typeof Stripe === 'undefined' )
            {
                const stripeScript = document.createElement( "script" );
                stripeScript.setAttribute( "src", process.env.VUE_APP_STRIPE_SCRIPT_URL );
                document.head.appendChild( stripeScript );
            }
            this.retrieveWallet();
            this.retrieveBankDetails();
            const minTopupValue = ( this.minTopupValue / 100 ).toFixed( 2 );
            this.topUpAmountTitle = eval( '`' + this.$t( "wallet.MinTopupAmount" ) + '`' );
            onServerMessage = this._onServerMessage.bind( this );
            globalEvents.on( "shio-signflow-notification", onServerMessage );
        }
    }
</script>

<style lang="sass" scoped>
  @import "../../../styles/style"

  .payment-form
    min-width: 400px
    align-self: center
    border-radius: 6px
    padding: 0

  .invoice-link
    color: $text-black !important
    text-decoration: underline

  .v-tab.v-tab--active
    color: $vivid-pink !important

  .tab-title
    text-transform: none
    font-size: 20px
    color: $text-black !important
    letter-spacing: normal

  .inputForm
    border-radius: 6px
    margin-bottom: 12px
    padding: 12px
    border: 1px solid rgba(50, 50, 93, 0.1)
    height: 48px
    font-size: 16px
    width: 100%
    background: white

  .zip-code
    border-radius: 0 0 6px 6px

  .card-element
    border-radius: 4px
    padding: 18px 12px 18px 12px
    border: 1px solid rgba(0, 0, 0, 0.42)
    height: 56px
    width: 100%
    background: white
    margin-bottom: 30px

  .card-element-second-line-left, .card-element-second-line-right
    padding: 18px 12px 18px 12px
    border: 1px solid rgba(0, 0, 0, 0.42)
    height: 56px
    width: 100%
    background: white
    margin-bottom: 30px

  .card-element-second-line-left
    border-radius: 4px

  .card-element-second-line-right
    border-radius: 4px

  .card-element.StripeElement.StripeElement--empty.StripeElement--focus,
  .card-element-second-line-left.StripeElement.StripeElement--empty.StripeElement--focus,
  .card-element-second-line-right.StripeElement.StripeElement--empty.StripeElement--focus
    border: 2px solid $vivid-pink

  .transactions-table th, td
    font-size: 16px !important
    color: $text-black
    font-weight: normal

  .transactions-table-tr.open td
    color: $vivid-pink !important

  .transactions-table-tr.paid td a
    color: $text-black !important

  .v-data-table.transactions-table
    border: 0

  .credit-card-info-title
    color: $dark-grey

  .billing-info-title
    color: $text-black
    padding-bottom: 30px

  .tax-info-explanation
    color: $text-black

  .saved-card-number
    color: rgba(0, 0, 0, 0.38)

  .overdue-alert-icon
    font-size: 20px
    color: $vivid-pink

  .incomplete-alert-icon
    font-size: 20px
    color: $vivid-pink !important

  .remove-icon
    font-size: 20px
    color: $vivid-pink
    cursor: pointer

  .card-div
    border: 1px solid #ddd
    border-radius: 4px
    padding: 15px
    color: rgba(0, 0, 0, 0.38)

  .underline-link
    text-decoration: underline

  @media (max-width: 599px)
    .mobile-center, .cancel-plan-btn
      text-align: center !important

    button
      width: 100%

    button.v-icon.remove-icon
      width: initial !important

  .consentUl
    margin-bottom: 16px

  .consentCheckBox
    color : black
    margin-top : 0 !important
    margin-left : -25px

  .subscription-title-div
    font-size: 16px
    font-weight: bold
    margin-top: 20px

  .subscription-info
    margin-bottom: 0

  .subscription-status-div
    display: inline
    padding: 2px 5px 2px 5px
    border-radius: 4px
    font-size: 15px
    font-weight: normal

  .trialing
    background-color: #ddfffe

  .canceled, .incomplete_expired
    background-color: #f2f2f2

  .active
    background-color: #D7F7C2

  .incomplete, .past_due
    background-color: #FFE7F2
</style>

<style lang="sass">
  #addBillingInfoBtn .v-btn
    max-height: 70px

  #addBillingInfoBtn span.v-btn__content
    white-space: normal
    flex: auto

  .v-input.select-tax-id-type input
    max-height: 0
    padding: 0

  @media (max-width: 599px)
    #addBillingInfoBtn .v-btn
      height: 100% !important

    .subscription-tabs .v-slide-group__prev.v-slide-group__prev--disabled
      display: none !important

    .payment-form
      min-width: auto !important

    .select-country .v-text-field__details, .select-tax-id-type .v-text-field__details
      min-height: auto
      margin-bottom: 0 !important
</style>
